import React from "react";
import { Button, Grid, Box } from "@mui/material";

const style = {
  background: "#FFF",
  borderRadius: "10px",
  padding: "20px",
  width: "80%",
};

const buttonStyle = {
  background: "#40A49C",
  color: "#FFF",
  borderRadius: "10px",
};

const UniversityCardLanding = ({ logo, name }) => {
  return (
    <>
      <Grid container sx={{ ...style }} justifyContent="center">
        <Grid item>
          <Box
            component="img"
            sx={{
              height: "200px",
              width: "200px",
            }}
            src={logo}
          />
        </Grid>
        <Grid item>
          <Button sx={{ ...buttonStyle }}>SEE PROGRAMMES</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UniversityCardLanding;

import {
  Chip,
  Grid,
  Typography,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { CoursesContext } from "../../../contexts/courses";

const style = {
  borderRadius: "10px",
  display: "flex",
  width: "100%",
  width: "90%",
};

const availabilityButton = {
  border: "1px solid #FFF",
  //   padding: "10px",
  color: "#FFF",
};

const enrollButton = {
  borderRadius: "50px",
  // padding: "10px",
  color: "#40A49C",
  background: "#FFF",
  width: "100%",
  letterSpacing: "0.1rem",
};

const CoursesCard = ({
  availability,
  background,
  title,
  enrolled,
  graduated,
  description,
}) => {
  const [courseDetails, setCourseDetails] = useState(false);

  const handleDisplayCourseDetails = () => {
    // setCourseDetails(true);
    setCourseDetails((prevCheck) => !prevCheck);
  };

  const handleCloseCourseDetails = () => {
    setCourseDetails(false);
  };
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ ...style }}
        onClick={handleDisplayCourseDetails}
      >
        <Grid
          item
          sx={{
            background: `url(${background})`,
            // background: "pink",
            backgroundSize: "cover",
            // width: "280px",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "20px",
            padding: "20px",
          }}
          direction="column"
        >
          {!courseDetails ? (
            <>
              <Grid item xs>
                <Grid
                  sx={{
                    alignItems: "flex-end",
                    display: "flex",
                    marginBottom: "20px",
                    fontSize: "16px",
                  }}
                >
                  {/* <Typography sx={{ color: "#FFF" }}>title</Typography> */}
                  <Chip
                    label={availability}
                    sx={{ color: "#FFF", border: "1px solid #FFF" }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs sx={{ display: "flex" }}>
                <Grid
                  sx={{
                    alignItems: "flex-end",
                    display: "flex",
                    marginBottom: "20px",
                    fontSize: "16px",
                  }}
                >
                  <Typography sx={{ color: "#FFF" }}>{title}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs sx={{ color: "#FFF" }} container>
                  <PersonIcon item />
                  <Typography item fontSize="0.8rem">
                    {enrolled} Enrolled
                  </Typography>
                </Grid>
                <Grid item xs sx={{ color: "#FFF" }} container>
                  <SchoolIcon item />
                  <Typography item fontSize="0.8rem">
                    {graduated} Graduated
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs container mb={"20px"}>
                <Grid
                  item
                  container
                  sx={{
                    // alignItems: "flex-end",
                    display: "flex",
                    // marginBottom: "20px",
                    fontSize: "16px",
                  }}
                >
                  <Grid item xs display={"flex"} justifyContent={"flex-start"}>
                    <Chip
                      label={availability}
                      sx={{ color: "#FFF", border: "1px solid #FFF" }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignContent={"flex-start"}
                    flexWrap={"wrap"}
                  >
                    <IconButton
                    // onClick={() => {
                    //   setCourseDetails(false);
                    // }}
                    >
                      <CloseRoundedIcon sx={{ color: "#FFF" }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs sx={{ display: "flex" }}>
                  <Typography sx={{ color: "#FFF" }}>{title}</Typography>
                </Grid>
              </Grid>
              <Grid item xs container direction={"column"}>
                <Grid item container direction={"row"} mb={"10px"}>
                  <Grid
                    item
                    xs
                    display={"flex"}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"light"} color={"#FFF"}>
                      Course Details
                    </Typography>
                  </Grid>
                  <Grid item xs display={"flex"} justifyContent={"flex-end"}>
                    <Button sx={{ ...enrollButton }}>Enroll</Button>
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    // fontSize: "4px",
                    color: "#FFF",
                    backgroundColor: "#FFF",
                    borderBottomWidth: "2px",
                  }}
                />
                <Grid item>
                  {/* <Typography>{description}</Typography> */}
                  <Typography
                    fontSize={12}
                    variant="subtitle2"
                    color={"#FFF"}
                    fontWeight={"light"}
                  >
                    {(description && description.substring(0, 250) + "...") ||
                      ""}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CoursesCard;

import React, { createContext, useState, useEffect } from "react";
import {
  getUniversities,
  getUniversityAdvice,
  getUniversityCourses,
} from "../../utils/firebase/firebase";

export const UniversitiesContext = createContext({
  universities: {},
  university: "",
  setUniversity: () => {},
  universityCourses: {},
  setUniversityCourses: () => {},
  universityAdvice: {},
  setUniversityAdvice: () => {},
});

export const UniversitiesProvider = ({ children }) => {
  const [universitiesData, setUniversitiesData] = useState({});
  const [university, setUniversity] = useState("");
  const [universityCourses, setUniversityCourses] = useState({});
  const [universityAdvice, setUniversityAdvice] = useState({});

  useEffect(() => {
    const getUniversitiesData = async () => {
      const universityData = await getUniversities("universities");
      setUniversitiesData(universityData);
    };

    getUniversitiesData();
  }, []);

  useEffect(() => {
    const getCoursesData = async () => {
      const universityData = await getUniversityCourses(university);
      setUniversityCourses(universityData);
      console.log("uni", university);
    };

    getCoursesData();
  }, [university]);

  useEffect(() => {
    const getAdvice = async () => {
      const adviceData = await getUniversityAdvice(university);
      setUniversityAdvice(adviceData);
      console.log("advice data", universityAdvice);
    };

    getAdvice();
  }, [university]);

  const value = {
    universitiesData,
    university,
    setUniversity,
    universityCourses,
    setUniversityCourses,
    universityAdvice,
    setUniversityAdvice,
  };

  return (
    <UniversitiesContext.Provider value={value}>
      {children}
    </UniversitiesContext.Provider>
  );
};

import React from "react";
import { Grid, Button } from "@mui/material";
import AppHeader from "../../components/appHeader";
import Logo from "../../assets/logos/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/footer";

const navBar = {
  height: "10vh",
  background: "#40A49C",
  width: "100%",
  // padding: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  color: "#FFF",
  marginBottom: "40px",
};

const logInButtonStyle = {
  borderRadius: "10px",
  padding: "10px",
  color: "#40A49C",
  background: "#FFF",
  width: "200px",
  letterSpacing: "0.1rem",
};

const PrivacyPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container direction={"column"}>
        <Grid container sx={{ ...navBar }} item direction={"row"}>
          <Grid
            xs
            item
            display={"flex"}
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <img
              src={Logo}
              // href="/"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              height="60%"
              width="auto"
            />
          </Grid>
          <Grid
            xs
            item
            display={"flex"}
            container
            justifyContent={"flex-end"}
            justifyItems={"center"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{
              pr: { xs: "4", sm: "5", md: "20", lg: "20", xl: "20" },
            }}
            // alignContent={"center"}
          >
            <Button
              href="/auth"
              sx={{ ...logInButtonStyle }}
              fullWidth
              variant="contained"
            >
              Login/Register
            </Button>
          </Grid>
        </Grid>
        <Grid item justifyContent={"center"} display={"flex"}>
          <AppHeader
            width={"80%"}
            section={"Terms & Condition/Privacy Policy"}
          />
        </Grid>
        <Grid
          item
          sx={{ width: "100%" }}
          justifyContent={"center"}
          display={"flex"}
        >
          <Grid sx={{ width: "80%" }}>
            <Typography variant="body2">
              Course Guide Privacy
              <br /> Policy Your privacy is important to us. It is Course
              Guide's policy to respect your privacy and comply with any
              applicable law and regulation regarding any personal information
              we may collect about you in our app and website.
              <br /> This policy is effective as of 16 January 2023 and was last
              updated on 16 January 2023.
              <br /> Information We Collect
              <br /> Information we collect includes both information you
              knowingly and actively provide us when using or participating in
              any of our services and promotions, and any information
              automatically sent by your devices in the course of accessing our
              products and services.
              <br /> Log Data
              <br /> When you visit our website, our servers may automatically
              log the standard data provided. It may include your device’s
              Internet Protocol (IP) address, your browser type and version, the
              pages you visit, the time and date of your visit, the time spent
              on each page, other details about your visit, and technical
              details that occur in conjunction with any errors you may
              encounter.
              <br /> Please be aware that while this information may not be
              personally identifying by itself, it may be possible to combine it
              with other data to personally identify individual persons.
              <br /> Personal Information
              <br /> We may ask for personal information which may include one
              or more of the following:
              <br /> ● Name
              <br /> ● Email
              <br /> ● Date of birth
              <br /> ● Phone/mobile number
              <br /> ● Home/mailing address
              <br /> ● Legitimate Reasons for Processing Your Personal
              Information
              <br /> ● We only collect and use your personal information when we
              have a legitimate reason for doing so. In this instance, we only
              collect personal information that is reasonably necessary to
              provide our services to you.
              <br /> Collection and Use of Information
              <br /> We may collect personal information from you when you do
              any of the following on our website:
              <br /> ● Sign up to receive updates from us via email or social
              media channels
              <br /> ● Use a mobile device or web browser to access our content
              <br /> ● Contact us via email, social media, or any similar
              technologies
              <br /> ● When you mention us on social media
              <br /> We may collect, hold, use, and disclose information for the
              following purposes, and personal information will not be further
              processed in a manner that is incompatible with these purposes:
              <br /> We may collect, hold, use, and disclose information for the
              following purposes, and personal information will not be further
              processed in a manner that is incompatible with these purposes:
              <br /> ● to enable you to customize or personalize your experience
              of our website
              <br /> ● to contact and communicate with you
              <br /> ● for analytics, market research, and business development,
              including operating and improving our app, associated
              applications, and associated social media platforms
              <br /> ● for advertising and marketing, including sending you
              promotional information about our products and services and
              information about third parties that we consider may be of
              interest to you
              <br /> ● to enable you to access and use our website, associated
              links, and associated social media platforms
              <br /> ● for internal record-keeping and administrative purposes
              <br /> ● to comply with our legal obligations and resolve any
              disputes that we may have
              <br /> ● for security and fraud prevention, and to ensure that our
              sites and apps are safe, secure, and used in line with our terms
              of use
              <br /> Please be aware that we may combine information we collect
              about you with general information or research data we receive
              from other trusted sources.
              <br /> Security of Your Personal Information
              <br /> When we collect and process personal information, and while
              we retain this information, we will protect it within commercially
              acceptable means to prevent loss and theft, as well as
              unauthorized access, disclosure, copying, use, or modification.
              <br /> Although we will do our best to protect the personal
              information you provide to us, we advise that no method of
              electronic transmission or storage is 100% secure, and no one can
              guarantee absolute data security. We will comply with laws
              applicable to us in respect of any data breach.
              <br /> You are responsible for selecting any password and its
              overall security strength, ensuring the security of your own
              information within the bounds of our services.
              <br /> How Long We Keep Your Personal Information
              <br /> We keep your personal information only for as long as we
              need to. This time period may depend on what we are using your
              information for, in accordance with this privacy policy. If your
              personal information is no longer required, we will delete it or
              make it anonymous by removing all details that identify you.
              <br />
              However, if necessary, we may retain your personal information for
              our compliance with a legal, accounting, or reporting obligation
              or for archiving purposes in the public interest, scientific, or
              historical research purposes, or statistical purposes.
              <br />
              International Transfers of Personal Information
              <br /> The personal information we collect is stored and/or
              processed where we or our partners, affiliates, and third-party
              providers maintain facilities. Please be aware that the locations
              to which we store, process, or transfer your personal information
              may not have the same data protection laws as the country in which
              you initially provided the information. If we transfer your
              personal information to third parties in other countries: (i) we
              will perform those transfers in accordance with the requirements
              of applicable law; and (ii) we will protect the transferred
              personal information in accordance with this privacy policy.
              <br /> Your Rights and Controlling Your Personal Information
              <br /> You always retain the right to withhold personal
              information from us, with the understanding that your experience
              of our website may be affected. We will not discriminate against
              you for exercising any of your rights over your personal
              information. If you do provide us with personal information you
              understand that we will collect, hold, use and disclose it in
              accordance with this privacy policy. You retain the right to
              request details of any personal information we hold about you.
              <br /> If we receive personal information about you from a third
              party, we will protect it as set out in this privacy policy. If
              you are a third party providing personal information about
              somebody else, you represent and warrant that you have such a
              person’s consent to provide the personal information to us.
              <br /> If you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time. We will provide you with the ability to
              unsubscribe from our email database or opt out of communications.
              Please be aware we may need to request specific information from
              you to help us confirm your identity.
              <br /> If you believe that any information we hold about you is
              inaccurate, out of date, incomplete, irrelevant, or misleading,
              please contact us using the details provided in this privacy
              policy. We will take reasonable steps to correct any information
              found to be inaccurate, incomplete, misleading, or out of date.
              <br /> If you believe that we have breached a relevant data
              protection law and wish to make a complaint, please contact us
              using the details below and provide us with full details of the
              alleged breach. We will promptly investigate your complaint and
              respond to you, in writing, setting out the outcome of our
              investigation and the steps we will take to deal with your
              complaint. You also have the right to contact a regulatory body or
              data protection authority in relation to your complaint.
              <br /> Use of Cookies
              <br /> We use “cookies” to collect information about you and your
              activity across our site. A cookie is a small piece of data that
              our website stores on your computer, and accesses each time you
              visit, so we can understand how you use our site. This helps us
              serve you content based on the preferences you have specified.
              <br /> Limits of Our Policy
              <br /> Our website may link to external sites that are not
              operated by us. Please be aware that we have no control over the
              content and policies of those sites, and cannot accept
              responsibility or liability for their respective privacy
              practices.
              <br /> Changes to This Policy
              <br /> At our discretion, we may change our privacy policy to
              reflect updates to our business processes, current acceptable
              practices, or legislative or regulatory changes. If we decide to
              change this privacy policy, we will post the changes here at the
              same link by which you are accessing this privacy policy.
              <br /> If required by law, we will get your permission or give you
              the opportunity to opt in to or opt out of, as applicable, any new
              uses of your personal information.
              <br /> Contact Us
              <br /> For any questions or concerns regarding your privacy, you
              may contact us using the following details:
              <br />
              Course Guide App
              <br /> https://www.facebook.com/CourseGuideapp
            </Typography>
          </Grid>
        </Grid>
        <Grid variant="body1" container sx={{ marginTop: "100px" }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacyPage;

import React, { createContext, useState, useEffect } from "react";
import { getNews } from "../../utils/firebase/firebase";

export const NewsContext = createContext({
  news: {},
  category: "",
  setCategory: () => {},
  setNewsData: () => {},
  setNewsCategory: () => {},
  newsCategory: "",
});

export const NewsProvider = ({ children }) => {
  const [newsData, setNewsData] = useState({});
  const [category, setCategory] = useState("");
  const [newsCategory, setNewsCategory] = useState("");

  useEffect(() => {
    const getNewsData = async () => {
      // setCategory(newsCategory);
      const newsdata = await getNews(category);
      setNewsData(newsdata);
    };

    getNewsData();
  }, [category]);

  const value = {
    newsData,
    category,
    setCategory,
    setNewsData,
    setNewsCategory,
    newsCategory,
  };

  return <NewsContext.Provider value={value}>{children}</NewsContext.Provider>;
};

import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  initializeAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  writeBatch,
  query,
  where,
  collectionGroup,
  addDoc,
} from "firebase/firestore";
import Axios from "axios";

// Initialize Firebase
let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const firebaseApp = initializeApp(config);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const db = getFirestore();
export { Axios };

export const createUserDocumnentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  if (!userAuth) return;
  const userDocRef = doc(db, "webUsers", userAuth.uid);
  // console.log(userDocRef);

  const userSnapShot = await getDoc(userDocRef);

  if (!userSnapShot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    // Set doc with new object
    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error creating user", error.message);
    }
  }

  return userDocRef;

  // if user data doesn not exist
  // create / set the document
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const getCouncillors = async () => {
  const collectionRef = collection(db, "consultants");
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  const counsilorsData = [];
  querySnapshot.forEach((doc) => {
    counsilorsData.push({ id: doc.id, ...doc.data() });
  });

  return counsilorsData;
};

export const getUniversities = async () => {
  console.log("getUniversities");

  const collectionRef = collection(db, "universities");
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  const universitiesData = [];
  querySnapshot.forEach((doc) => {
    universitiesData.push({ id: doc.id, ...doc.data() });
  });

  return universitiesData;
};

export const getCoursesByCategory = async (category) => {
  console.log("getCoursesByCategory");
  const collectionRef = collection(db, "universities");
  const q = query(collectionRef, where("category", "==", `${category}`));

  const courseSnapShot = await getDocs(q);

  const coursesData = [];
  courseSnapShot.forEach((doc) => {
    coursesData.push({ id: doc.id, ...doc.data() });
  });

  return coursesData;
};

export const getScholarships = async () => {
  console.log("getScholarships");

  const collectionRef = collection(db, "scholarships");
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  const scholarshipsData = [];
  querySnapshot.forEach((doc) => {
    scholarshipsData.push({ id: doc.id, ...doc.data() });
  });

  return scholarshipsData;
};

export const getNews = async (category) => {
  console.log("getNews");

  const collectionRef = collection(db, "news");
  const q = query(collectionRef, where("category", "==", `${category}`));

  const newsSnapShot = await getDocs(q);

  const newsData = [];
  newsSnapShot.forEach((doc) => {
    newsData.push({ id: doc.id, ...doc.data() });
  });

  return newsData;
};

export const getUserData = async (userID) => {
  console.log("getUserData");

  // console.log("auth", auth);
  const collectionRef = collection(db, "users");
  const q = query(collectionRef, where("uid", "==", `${userID}`));
  const userSnapShot = await getDocs(q);

  const userData = [];
  userSnapShot.forEach((doc) => {
    userData.push({ id: doc.id, ...doc.data() });
  });

  console.log("user Data", userData);

  return userData;
};

export const getUniversityCoursesByCategory = async (
  universityID,
  universityName,
  category
) => {
  // console.log("firebase uni", university);
  console.log("getUniversityCourseByCategory");

  const q = query(
    collection(db, "universities", `${universityID}`, "courses"),
    where("category", "==", `${category}`)
  );

  const querySnapshot = await getDocs(q);

  const coursesData = [];
  querySnapshot.forEach((doc) => {
    coursesData.push({ id: doc.id, ...doc.data() });
  });

  return coursesData;
};

export const getAllCoursesByCategory = async (category) => {
  console.log("getAllCoursesByCategory");

  const q = query(
    collectionGroup(db, "courses"),
    where("featured", "==", "true")
  );

  const courseSnapShot = await getDocs(q);

  const coursesData = [];
  courseSnapShot.forEach((doc) => {
    coursesData.push({ id: doc.id, ...doc.data() });
  });
  // console.log(coursesData);
  var categoryCourses = {};

  categoryCourses = Object.values(coursesData).filter((course) => {
    return course.category === category;
  });
  // console.log("category courses", categoryCourses);

  return categoryCourses;
};

export const getUniversityCourses = async (universityID, universityName) => {
  console.log("getUniversityCourses");

  console.log("firebase uni", universityID);

  const q = query(
    collection(db, "universities", `${universityID}`, "courses")
    // where("category", "==", `${category}`)
  );

  const querySnapshot = await getDocs(q);

  const coursesData = [];
  querySnapshot.forEach((doc) => {
    coursesData.push({ id: doc.id, ...doc.data() });
  });

  // console.log(coursesData);

  return coursesData;
};

export const addAppointment = async (data) => {
  const docRef = collection(db, "appointments");

  addDoc(docRef, data)
    .then((docRef) => {
      console.log("A new appointment has been added.");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addAdvice = async (universityID, data) => {
  const docRef = collection(
    db,
    "universities",
    `${universityID}`,
    "advice_forums"
  );

  addDoc(docRef, data)
    .then((docRef) => {
      console.log("A new advice has been added.");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getUniversityAdvice = async (universityID) => {
  const q = query(
    collection(db, "universities", `${universityID}`, "advice_forums"),
    where("status", "==", "approved")
  );

  const querySnapshot = await getDocs(q);

  const adviceData = [];
  querySnapshot.forEach((doc) => {
    adviceData.push({ id: doc.id, ...doc.data() });
  });

  console.log(adviceData);

  return adviceData;
};

export const addBookingMail = async (data) => {
  const docRef = collection(db, "counsillorMails");

  addDoc(docRef, data)
    .then((docRef) => {
      console.log("Booking mail has been sent.");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addAdviceMail = async (data) => {
  const docRef = collection(db, "adviceMail");

  addDoc(docRef, data)
    .then((docRef) => {
      console.log("Advice mail has been sent.");
    })
    .catch((error) => {
      console.log(error);
    });
};

import React, { useContext, useEffect, Fragment, useState } from "react";
import NewsCard from "../cards/news";
import { NewsContext } from "../../contexts/news";
import { Grid, CircularProgress } from "@mui/material";

const NewsCategory = ({ category }) => {
  const { newsData, setNewsCategory, setCategory } = useContext(NewsContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCategory(category);
  }, []);

  if (loading && !newsData) {
    return <CircularProgress />;
  }

  if (newsData.length === 0 || !newsData) {
    // return <EmptyState />;
    return <CircularProgress />;
  }

  return (
    <>
      {/* {Object.keys(newsData || {}).map((id) => (
        <Fragment key={id}> */}

      <Grid
        rowSpacing={2}
        columnSpacing={2}
        container
        direction="row"
        sx={{ display: "flex" }}
      >
        {/* {Object.keys(coursesData || {}).map((id) => (
        <Fragment key={id}> */}
        {loading && <CircularProgress />}
        {Object.values(newsData).map((news) => (
          <Grid item xs={12} sm={12} lg={3} xl={3} md={3} key={news.id}>
            <NewsCard
              title={news.title}
              background={news.imageUrl}
              author={news.author}
              date={news.date}
              description={news.description}
              link={news.link}
              tag={news.tag}
            />
          </Grid>
          //   ))}
          // </Fragment>
        ))}
      </Grid>
    </>
  );
};

export default NewsCategory;

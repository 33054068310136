import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BookIcon from "@mui/icons-material/Book";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import NewsCategory from "../../newsCategory";
import { styled } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            pt: 3,
            mt: 1,
            // justifyContent: { sx: "center", xs: "center", md: "flex-start" },
            // display: "flex",
            // background: "blue",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  ".css-11yukd5-MuiTabs-indicator": {
    display: "none",
  },
  ".css-1f2xyxh-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    background: "#40A49C",
    color: "#FFF",
    textTransform: "capitalize",
    letterSpacing: "1px",
  },
  ".css-1f2xyxh-MuiButtonBase-root-MuiTab-root": {
    background: "#FFF",
    border: "1px solid #40A49C",
    borderRadius: "50px",
    minHeight: "30px",
    marginRight: "20px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    color: "#40A49C",
  },
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#343434",
    display: "none",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    background: "#FFF",
    border: "1px solid #40A49C",
    borderRadius: "50px",
    minHeight: "30px",
    marginRight: "20px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    color: "#40A49C",
    "&.Mui-selected": {
      background: "#40A49C",
      color: "#FFF",
      textTransform: "capitalize",
      letterSpacing: "1px",
    },
  })
);

export default function NewsIconTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="For You" {...a11yProps(0)} />
          <StyledTab label="Featured" {...a11yProps(1)} />
          <StyledTab label="Top Stories" {...a11yProps(2)} />
        </StyledTabs>
      </Box>
      {/* <Tabs
          sx={{ ...tabStyle }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
        >
          <Tab
            icon={<BookIcon />}
            iconPosition="start"
            label="For You"
            {...a11yProps(0)}
          />
          <Tab
            icon={<WhatshotIcon />}
            iconPosition="start"
            label="Featured"
            {...a11yProps(1)}
          />
          <Tab
            icon={<NewReleasesIcon />}
            iconPosition="start"
            label="Top Stories"
            {...a11yProps(2)}
          />
        </Tabs> */}
      {/* </Box> */}
      <TabPanel value={value} index={0}>
        <NewsCategory category={"For You"} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NewsCategory category={"Featured"} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <NewsCategory category={"Top Stories"} />
      </TabPanel>
    </Box>
  );
}

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BookIcon from "@mui/icons-material/Book";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import CoursesCard from "../../cards/courses";
import { CoursesContext } from "../../../contexts/courses";
import CoursesCategory from "../../coursesCategory";
import { styled } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, mt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  ".css-11yukd5-MuiTabs-indicator": {
    display: "none",
  },
  ".css-1f2xyxh-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    background: "#40A49C",
    color: "#FFF",
    textTransform: "capitalize",
    letterSpacing: "1px",
  },
  ".css-1f2xyxh-MuiButtonBase-root-MuiTab-root": {
    background: "#FFF",
    border: "1px solid #40A49C",
    borderRadius: "50px",
    minHeight: "30px",
    marginRight: "20px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    color: "#40A49C",
  },
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#343434",
    display: "none",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    // color: "#DEDFE3",
    // textTransform: "capitalize",
    // fontSize: "20px",
    // fontWeight: 700,
    // letterSpacing: "1.5px",
    // "&.Mui-selected": {
    //   color: "#343434",
    // },
    // "&.Mui-focusVisible": {
    //   backgroundColor: "rgba(100, 95, 228, 0.32)",
    // },

    background: "#FFF",
    border: "1px solid #40A49C",
    borderRadius: "50px",
    minHeight: "30px",
    marginRight: "20px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    color: "#40A49C",
    "&.Mui-selected": {
      background: "#40A49C",
      color: "#FFF",
      textTransform: "capitalize",
      letterSpacing: "1px",
    },
  })
);

export default function IconTabs() {
  const { coursesData, setCategory, category, setCoursesData } =
    useContext(CoursesContext);

  // const filteredCategory = () => {
  //   return coursesData.filter((course) =>
  //     coursesData.category.include("Newest")
  //   );
  // };

  // useEffect(() => {
  //   setCategory("Newest");
  //   console.log("icons", coursesData);
  // }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          // sx={{ ...tabStyle }}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <StyledTab
            label="Marketable"
            {...a11yProps(0)}
            icon={<BookIcon />}
            iconPosition="start"
          />
          <StyledTab
            label="Trending"
            {...a11yProps(1)}
            icon={<WhatshotIcon />}
            iconPosition="start"
          />
          <StyledTab
            label="Newest"
            {...a11yProps(2)}
            icon={<NewReleasesIcon />}
            iconPosition="start"
          />
          <StyledTab
            label="Advanced"
            {...a11yProps(3)}
            icon={<RocketLaunchIcon />}
            iconPosition="start"
          />
        </StyledTabs>

        {/* <Tabs
          sx={{ ...tabStyle }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            icon={<BookIcon />}
            iconPosition="start"
            label="Marketable"
            {...a11yProps(0)}
          />
          <Tab
            icon={<WhatshotIcon />}
            iconPosition="start"
            label="Trending"
            {...a11yProps(1)}
          />
          <Tab
            icon={<NewReleasesIcon />}
            iconPosition="start"
            label="Newest"
            {...a11yProps(2)}
          />
          <Tab
            icon={<RocketLaunchIcon />}
            iconPosition="start"
            label="Advanced"
            {...a11yProps(3)}
          />
        </Tabs> */}
      </Box>
      <TabPanel value={value} index={0}>
        <CoursesCategory category="Marketable" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CoursesCategory category="Trending" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CoursesCategory category="Newest" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CoursesCategory category="Advanced" />
      </TabPanel>
    </Box>
  );
}

import { Grid, Typography, Box } from "@mui/material";
import React from "react";

const style = {
  background: "#FFF",
  // padding: "20px",
  padding: { sm: "10px", xs: "10px", md: "10px", lg: "10px" },
  borderRadius: "10px",
  color: "#40A49C",
  // width: "80%",
  width: { sm: "100%", xs: "100%", lg: "80%", md: "80%", xl: "80%" },
};

const storeName = {
  fontWeight: 500,
  // fontSize: "25px",
};

const storeDesc = {
  fontWeight: "light",
  // fontSize: "18px",
};

const logo = {
  //   padding: "5px",
  width: "60px",
  height: "60px",
};

const AppButton = ({ icon, name, desc }) => {
  return (
    <>
      <Grid container sx={{ ...style }} direction="row">
        <Grid
          item
          // xs={12}
          // sm={12}
          // md={4}
          xs={4}
          alignContent="center"
          sx={{ display: "flex" }}
        >
          <Box
            component="img"
            sx={{
              ...logo,
              // maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
            }}
            src={icon}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          // xs={12} sm={12} md={8}
          xs={8}
        >
          <Grid item>
            <Typography variant="subtitle1" sx={{ ...storeDesc }}>
              {desc}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ ...storeName }}>
              {name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AppButton;

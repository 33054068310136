import { Grid, Typography, SvgIcon, Box, Button } from "@mui/material";
import React from "react";
import AppStoreLogo from "../../assets/appStoreW.svg";
import PlayStoreLogo from "../../assets/playStore.svg";
import consellorSectionPic from "../../assets/consellorSection.jpg";
import aboutSectionPic from "../../assets/about.jpg";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import Bullet from "../../assets/black-circle.png";
import RemoveIcon from "@mui/icons-material/Remove";
import Team from "../cards/team";
import ProfilePic from "../../assets/profilePlaceholder.jpg";
import UniversityCard from "../cards/universitiesLanding";
import GUCLogo from "../../assets/logos/gucLogo.png";
import BothoLogo from "../../assets/logos/bothoLogoLong.png";
import BoiLogo from "../../assets/logos/boitakaneloLogo.png";
import UBLogo from "../../assets/logos/ubLogo.png";
import PsycTestPic from "../../assets/brainstorm.png";
import Footer from "../footer";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/logo.png";
import MainHeader from "../../assets/mainHeader.png";
import CounsillorBG from "../../assets/counsellorBG2.png";

const Landing = () => {
  const wrapper = {
    width: "100%",
    height: "100%",
  };

  const landingSection = {
    height: "90vh",
    color: "#FFF",
    background: `url(${MainHeader})`, // 90 opacity
    backgroundSize: {
      sm: "100% 100%",
      xs: "100% 100%",
      md: "cover",
      lg: "cover",
      xl: "cover",
    },
    // backgroundSize: "100% 100%",
    // backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    // opacity: "0.6",
  };

  const overlay = {
    background: "#00000050",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
  };

  const appButton = {
    background: "#40A49C",
    borderRadius: "10px",
    // margin: "20px",
    // padding: "10px",
    // padding: "50px 0px 50px 50px",
  };

  const mainTitle = {
    // fontSize: "30px",
    // fontSize: "2rem",
    fontWeight: "Bold",
    letterSpacing: "2px",
  };

  const subTitle = {
    // fontSize: "30px",
    // fontSize: "30px",
    fontWeight: "Light",
    letterSpacing: "2px",
  };

  const storeName = {
    fontWeight: "400",
    // fontSize: "50px",
  };

  const storeDesc = {
    // fontSize: "30px",
    fontWeight: "Light",
  };

  const storeLogo = {
    height: "100px",
    width: "100px",
    // paddingLeft: "20px",
    // paddingTop: "20px",
    // paddingBottom: "20px",
  };

  const consellorSection = {
    minheight: "60vh",
    // maxHeight: "50vh",
    maxheight: "100%",
    width: "100%",
    // background: "blue",
    // paddingTop: "10vh",
  };

  const consellorSectionBG = {
    background: `url(${consellorSectionPic})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: { lg: "0px -150px" },
    // backgroundOrigin: "border-box",
  };

  const consellorSectionText = {
    // paddingLeft: "60px",
    // paddingTop: "100px",
    // background: "#40A49C90",
    background: `url(${CounsillorBG})`, // 90 opacity
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#FFF",
    height: "100%",
    // backgroundPosition: { md: "0px -400px", lg: "0px -400px" },
    // backgroundPosition: { md: "0px -400px", lg: "0px -400px" },
    backgroundSize: "cover",

    // paddingRight: "60px",
  };

  const consellorSectionOverlay = {
    background: "#40a49c80",
    width: "100%",
    height: "100%",
    padding: "50px",

    // background: "#00000050",
    // width: "100%",
    // height: "100%",
    backdropFilter: "blur(8px)",
  };

  const consellorSectionHeading = {
    // fontSize: "40px",
    fontWeight: 500,
    marginBottom: "30px",
    letterSpacing: "2px",
  };

  const consellorSectionDesc = {
    // fontSize: "20px",
    fontWeight: "light",
    marginBottom: "30px",
    letterSpacing: "2px",
  };

  const contactButton = {
    background: "#FFF",
    color: "#40A49C",
    borderRadius: "10px",
    padding: "10px",
    height: "50px",
    fontSize: "18px",
  };

  const sectionTitles = {
    // fontSize: "60px",
    fontWeight: 700,
    letterSpacing: "2px",
    lineHeight: 1,
    marginBottom: "40px",
  };

  const teamSmall = {
    // marginLeft: "20px",
    maxheight: "250px",
    width: "250px",
    marginTop: 0,
    marginBottom: 0,
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  };

  const teamMain = {
    height: "100%",
    width: "100%",
    display: "flex",
  };

  const psychTestButton = {
    background: "#40A49C",
    color: "#FFF",
    padding: "20px",
  };

  const navBar = {
    height: "10vh",
    background: "#40A49C",
    width: "100%",
    // padding: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    color: "#FFF",
  };

  const logInButtonStyle = {
    borderRadius: "10px",
    padding: "10px",
    color: "#40A49C",
    background: "#FFF",
    width: "200px",
    width: { xs: "100%", sm: "100%", md: "200px", lg: "200px" },
    letterSpacing: "0.1rem",
  };

  const location = useLocation();
  const isAuth = () => {
    if (location.pathname === "/auth") return true;
  };

  // console.log(location.pathname);

  const navigate = useNavigate();

  return (
    <>
      <Grid container direction="column" sx={{ ...wrapper }}>
        <Grid container sx={{ ...navBar }} item direction={"row"}>
          <Grid
            xs
            item
            display={"flex"}
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <img
              src={Logo}
              // href="/"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              height="60%"
              width="auto"
            />
          </Grid>
          <Grid
            xs
            item
            display={"flex"}
            container
            justifyContent={"flex-end"}
            justifyItems={"center"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{
              pr: { xs: "4", sm: "5", md: "20", lg: "20", xl: "20" },
            }}
            // alignContent={"center"}
          >
            <Button
              href="/auth"
              sx={{ ...logInButtonStyle }}
              fullWidth
              variant="contained"
            >
              Login/Register
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ ...landingSection }}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{ ...overlay }}
          >
            <Grid
              item
              sx={{
                justifyContent: "center",
                width: { xs: "100%", sm: "100%", md: "70%", lg: "70%" },
                marginBottom: "30px",
                // padding: { sm: 1, xs: 1 },
              }}
            >
              <Typography variant="h3" sx={{ ...mainTitle }} align="center">
                Your Career Partner Whose Just A Click Away
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                width: { xs: "100%", sm: "100%", md: "70%", lg: "70%" },
                marginBottom: "30px",
              }}
            >
              <Typography variant="h5" sx={{ ...subTitle }} align="center">
                We care about what you decide to do about your future. Trust and
                believe we have the necessary resource to help you achieve them
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              columnSpacing={4}
              rowSpacing={4}
              sx={{
                width: { xs: "100%", sm: "100%", md: "70%", lg: "70%" },
              }}
            >
              <Grid item xs={12} md={6} lg={6} xl={4}>
                <Grid container sx={{ ...appButton }} xs direction="row" item>
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                    padding={2}
                  >
                    <Box
                      component="img"
                      sx={{
                        ...storeLogo,
                      }}
                      src={PlayStoreLogo}
                    />
                  </Grid>
                  <Grid
                    xs={8}
                    item
                    container
                    direction="column"
                    display="flex"
                    justifyContent={"center"}
                    alignContent={"flex-start"}
                  >
                    <Grid>
                      <Typography variant="h6" sx={{ ...storeDesc }}>
                        Get it on
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="h5" sx={{ ...storeName }}>
                        Google Play
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={4}>
                <Grid container sx={{ ...appButton }} xs direction="row" item>
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                    padding={2}
                  >
                    <Box
                      component="img"
                      sx={{
                        ...storeLogo,
                      }}
                      src={AppStoreLogo}
                    />
                  </Grid>
                  <Grid
                    xs={8}
                    item
                    container
                    direction="column"
                    display="flex"
                    justifyContent={"center"}
                    alignContent={"flex-start"}
                  >
                    <Grid>
                      <Typography variant="h6" sx={{ ...storeDesc }}>
                        Download on the
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="h5" sx={{ ...storeName }}>
                        App Store
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Contact section */}
        <Grid
          item
          container
          direction="row"
          //   justifyContent="center"
          //   alignItems="center"
          sx={{ ...consellorSection }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sm={12}
            sx={{ ...consellorSectionBG }}
          ></Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ ...consellorSectionText }}
            justifyContent="center"
            alignContent={"center"}
            // padding={5}
            // alignItems="center"
            direction="column"
            display="flex"
            color="#FFF"
            container
          >
            <Grid sx={{ ...consellorSectionOverlay }}>
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{ ...consellorSectionHeading }}
                  align="left"
                >
                  Do not be too timid and squeamish about your actions
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{ ...consellorSectionDesc }}
                  align="left"
                >
                  We care about what you decide to do about your future. Trust
                  and believe we have the necessary resource to help you achieve
                  them. We care about what you decide to do about your future.
                  Trust and believe we have the necessary resource to help you
                  achieve them. We care about what you decide to do about your
                  future. Trust and believe we have the necessary resource to
                  help you achieve them
                </Typography>
              </Grid>
              <Grid item>
                <Button sx={{ ...contactButton }}>Contact a Counsellor</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* About us section */}
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: 5 }}
        >
          <Grid sx={{ marginBottom: "50px" }}>
            <Typography variant="h3" sx={{ ...sectionTitles }}>
              About Us
            </Typography>
          </Grid>
          <Grid sx={{ marginBottom: "50px" }}>
            <Box
              component="img"
              sx={{
                // height: "500px",
                height: {
                  xs: "auto",
                  sm: "auto",
                  md: "500px",
                  lg: "500px",
                  xl: "500px",
                },
                width: { xs: "100%", sm: "100%" },
                // "800px",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
              }}
              src={aboutSectionPic}
            />
          </Grid>
          <Grid
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
                lg: "80%",
                xl: "80%",
              },
            }}
          >
            <Typography variant="body1" align="center">
              Course Guide is platfrom which exists as a solution that answer to
              the question after high school "are young people really equipped
              enough to make informed decisions after life long careers."
            </Typography>
          </Grid>
          <Grid sx={{ marginBottom: "40px", marginTop: "40px" }}>
            <Typography fontWeight="bold" variant="h6">
              OUR VALUES INCLUDE
            </Typography>
          </Grid>
          <Grid
            container
            // direction="row"
            justifyContent="center"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
                lg: "80%",
                xl: "80%",
              },
              marginBottom: "40px",
              flexDirection: {
                sm: "column",
                xs: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Grid item xs>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                <li>Data Driven Solutions</li>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                <li>Convenience</li>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                <li>Digital And Social Inclusion</li>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                <li>Easy Accessibility</li>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                <li>Simplicity And Affordability</li>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
                lg: "80%",
                xl: "80%",
              },
              marginBottom: "40px",
            }}
          >
            <Typography variant="body1" align="center">
              Since its release in 2021., Course Guide has strived to equip
              users with information on the opportunities that are available for
              them, what is relevant in the 21st century, all while being
              inclusive of everyone especially those in marginalized areas who
              have trouble accessing resources. Not forgetting the data driven
              decision making features that make sure we stay ahead in the
              digital age
            </Typography>
          </Grid>
        </Grid>
        {/* Team Section */}
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: 5 }}
        >
          <Grid>
            <Typography variant="h3" sx={{ ...sectionTitles }}>
              Meet The Team
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
            }}
            columnSpacing={4}
            rowSpacing={4}
          >
            {/* <Grid item md={5} xs={12} sm={12}>
              <Grid xs={12} sx={{ ...teamMain }}>
                <Team
                  name={"Tefo Matladiile"}
                  position={"Founder and Managing Director"}
                  company={"Course Guide"}
                  picture={ProfilePic}
                  height={"620px"}
                />
              </Grid>
            </Grid> */}
            {/* <Grid
              item
              md={7}
              xs={12}
              sm={12}
              container
              direction="row"
              rowSpacing={4}
              columnSpacing={4}
            > */}
            {/* Tefo */}
            <Grid md={3} xs={12} sm={12} sx={{ ...teamSmall }} item>
              <Team
                name={"Tefo Matladiile"}
                position={"Founder/Managing Director"}
                company={"Course Guide"}
                picture={ProfilePic}
                height={"250px"}
              />
            </Grid>

            {/* Thembani */}
            <Grid md={3} xs={12} sm={12} sx={{ ...teamSmall }} item>
              <Team
                name={"Thembani Maika"}
                position={"Business Developer"}
                company={"Le-Ma Systems"}
                picture={ProfilePic}
                height={"250px"}
              />
            </Grid>

            {/* Pako */}
            <Grid md={3} lg={3} xs={12} sm={12} sx={{ ...teamSmall }} item>
              <Team
                name={"Pako Chalebgwa"}
                position={"Software Developer"}
                company={"Le-Ma Systems"}
                picture={ProfilePic}
                height={"250px"}
              />
            </Grid>
            {/* Amaal */}
            <Grid md={3} xs={12} sm={12} sx={{ ...teamSmall }} item>
              <Team
                name={"Amaal Leshiba"}
                position={"Software Developer"}
                company={"Le-Ma Systems"}
                picture={ProfilePic}
                height={"250px"}
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>

        {/* Partners Section */}
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            // marginTop: "50px",
            // marginBottom: "50px",
            background: "#F7F8F9",
            height: "100%",
          }}
        >
          <Grid sx={{ marginTop: "50px" }}>
            <Typography variant="h3" sx={{ ...sectionTitles }}>
              Universities
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
                lg: "80%",
                xl: "80%",
              },
              marginBottom: "40px",
            }}
            columnSpacing={4}
            rowSpacing={4}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              justifyContent={"center"}
              alignContent={"center"}
              display="flex"
            >
              <UniversityCard name={"GUC"} logo={GUCLogo} />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              justifyContent={"center"}
              alignContent={"center"}
              display="flex"
            >
              <UniversityCard name={"Boitekanelo"} logo={BoiLogo} />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              justifyContent={"center"}
              alignContent={"center"}
              display="flex"
            >
              <UniversityCard name={"UB"} logo={UBLogo} />
            </Grid>
          </Grid>
        </Grid>

        {/* Psycology Test Section */}
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            // marginTop: "50px",
            marginBottom: "50px",
            // background: "#F7F8F9",
            height: "100%",
          }}
        >
          <Grid sx={{ marginTop: "50px" }}>
            <Box
              component="img"
              sx={{
                height: "250px",
                width: "250px",
              }}
              src={PsycTestPic}
            />
          </Grid>
          <Grid sx={{ marginTop: "50px" }}>
            <Typography
              variant="subtitle1"
              sx={{ ...sectionTitles }}
              align="center"
            >
              Be Yourself, Everyone Else Is Taken
            </Typography>
          </Grid>
          <Grid
            sx={{
              marginBottom: "40px",
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
                lg: "70%",
                xl: "70%",
              },
            }}
          >
            <Typography variant="body1" align="center">
              We understand that different personalities are suited for
              different careers and before you make a decision to commit to what
              might be our ideal career, how about we help you figure which
              personality you are
            </Typography>
          </Grid>
          <Grid sx={{ marginBottom: "40px" }}>
            <Button sx={{ ...psychTestButton }}>REGISTER FOR THE TEST</Button>
          </Grid>
        </Grid>
        {/* Scolarships section */}
        {/* <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            // marginTop: "50px",
            marginBottom: "50px",
            height: "100%",
          }}
        >
          <Grid sx={{ marginTop: "50px" }}>
            <Typography sx={{ ...sectionTitles }}>
              Our Scholarship Programmes
            </Typography>
          </Grid>
        </Grid> */}
        {/* Footer */}
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default Landing;

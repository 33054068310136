import React, { useState, useContext, Suspense } from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./routes/home";
import Authentication from "./routes/authentication";
import Navigation from "./routes/navigation/landingNavigation";
import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./routes/landingPage";
import Universities from "./routes/universities";
import SiteNavigation from "./routes/navigation/siteNavigation";
import PersonalityTest from "./routes/personalityTest";
import CareerCounsellor from "./routes/careerCounsellor";
import MyProfile from "./routes/myProfile";
import Logout from "./routes/logout";
import { UserContext, UserProvider } from "./contexts/user";
import Layout from "./components/layout";
import FAQPage from "./routes/faq";
import PrivacyPage from "./routes/privacyPolicy";
import { UniversitiesProvider } from "./contexts/universities";
import { CounsilorsProvider } from "./contexts/counsellors";

const App = () => {
  // const [userLoggedIn, setUserLoggedIn] = useState(false);

  const { setCurrentUser, currentUser } = useContext(UserContext);
  return (
    <Routes>
      <Route path="/" element={currentUser ? <Layout /> : <LandingPage />}>
        <Route index element={<Home />} />
        <Route path="/dashboard" element={<Home />} />
        <Route
          path="/universities"
          element={
            <UniversitiesProvider>
              <Universities />
            </UniversitiesProvider>
          }
        />
        <Route path="/personality-test" element={<PersonalityTest />} />
        <Route
          path="/career-counsellor"
          element={
            <CounsilorsProvider>
              <CareerCounsellor />
            </CounsilorsProvider>
          }
        />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/logout" element={<Logout />} />
      </Route>
      <Route
        path="/auth"
        element={
          <UserProvider>
            <Authentication />
          </UserProvider>
        }
      />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/privacy-policy" element={<PrivacyPage />} />
    </Routes>
  );
};

export default App;

import { Grid } from "@mui/material";
import React, { useContext, Fragment } from "react";
import ScholarshipCard from "../cards/scholarship";
import { ScholarshipsContext } from "../../contexts/scholarships";

const Scholarships = () => {
  const { scholarshipsData } = useContext(ScholarshipsContext);
  return (
    <>
      <Grid
        rowSpacing={4}
        // columnSpacing={2}
        container
        direction="row"
        sx={{ display: "flex" }}
      >
        {/* {Object.keys(scholarshipsData || {}).map((id) => (
          <Fragment key={id}> */}
        {Object.values(scholarshipsData).map((scholarship) => (
          <Grid item xs={12} md={4} lg={4} xl={3} key={scholarship.id}>
            <ScholarshipCard
              item
              title={scholarship.title}
              background={scholarship.imageUrl}
              link={scholarship.link}
              description={scholarship.description}
              data={scholarship}
            />
          </Grid>
        ))}
        {/* </Fragment>
        ))} */}
      </Grid>
    </>
  );
};

export default Scholarships;

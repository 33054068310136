import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccountSettings from "../../accountSettings";
import MyCourses from "../../myCourses";
import { styled } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  ".css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "#343434",
  },
  ".css-1a4cg4j-MuiButtonBase-root-MuiTab-root": {
    color: "#DEDFE3",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 700,
    letterSpacing: "1.5px",
  },
  ".css-11yukd5-MuiTabs-indicator": {
    background: "#40A49C",
  },
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#343434",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: "#DEDFE3",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 700,
    letterSpacing: "1.5px",
    "&.Mui-selected": {
      color: "#343434",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function ProfileTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        {/* <Tabs
          sx={{ ...tabStyle }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Account Settings" {...a11yProps(0)} />
          <Tab label="My Courses" {...a11yProps(1)} />
        </Tabs> */}
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Account Settings" {...a11yProps(0)} />
          <StyledTab label="My Courses" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AccountSettings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MyCourses />
      </TabPanel>
    </Box>
  );
}

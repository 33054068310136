import { async } from "@firebase/util";
import React, { useState, useContext } from "react";
import {
  createAuthUserWithEmailAndPassword,
  createUserDocumnentFromAuth,
} from "../../utils/firebase/firebase";
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";
import { UserContext } from "../../contexts/user";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Step1 from "./step1";
import Step2 from "./step2";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const defaultformFields = {
  fullname: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const card = {
  background: "#40A49C",
  borderRadius: "10px",
  height: { xs: "70%", sm: "70%", md: "75%", lg: "75%", xl: "75%" },
  // width: "25%",
  // padding: "40px",
  color: "#FFF",
  padding: 20,
};

const textBoxStyle = {
  background: "#FFF",
  borderRadius: "10px",
  ".css-10zro85-MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "none",
  },
  ".css-10zro85-MuiInputBase-root-MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  ".css-10zro85-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before":
    {
      borderBottom: "none",
    },
  ".css-1g95crv-MuiFormControl-root-MuiTextField-root .css-10zro85-MuiInputBase-root-MuiFilledInput-root:after":
    {
      borderBottom: "none",
    },
};

const buttonStyle = {
  background: "#C6F327",
  color: "#FFFFFF",
  // height: "56px",
};

const headingStyle = {
  // fontSize: "30px",
};

const datePickerStyle = {
  color: "#FFFFFF",
};

const SignUpForm = () => {
  const [formFields, setFormFields] = useState(defaultformFields);
  const [timeValue, setTimeValue] = useState(dayjs());

  // Distructure fields for use later, probably
  const {
    fullname,
    email,
    gender,
    phoneNumber,
    dob,
    postalAddress,
    residentailAddress,
    password,
    confirmPassword,
  } = formFields;

  const { setCurrentUser, isSignIn, setIsSignIn, isStep1, setIsStep1 } =
    useContext(UserContext);

  const resetFormFields = () => {
    setFormFields(defaultformFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // console.log(formFields);

    if (isStep1) {
      setIsStep1(false);
    }

    if (password !== confirmPassword) {
      alert("Password do not match");
      return;
    }

    // alert("Success");

    // try {
    //   const user = await createAuthUserWithEmailAndPassword(email, password);

    //   // setCurrentUser(user);

    //   await createUserDocumnentFromAuth(user, { fullname }); // pass fullname as additional field in case it is null
    //   resetFormFields();
    // } catch (error) {
    //   if (error.code === "auth/email-already-in-use") {
    //     alert("Cannot create user email already in use.");
    //   }
    //   console.log("User creation encountered an error", error.message);
    // }
  };

  const handleTimeChange = (timeValue) => {
    setTimeValue(timeValue);
    setFormFields({
      ...formFields,
      dob: dayjs(timeValue.$d).format("DD-MM-YYYY"),
    });
    // console.log(dayjs(timeValue.$d).format("DD-MM-YYYY"));
    // console.log(dayjs(timeValue));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  // const handleChange = (name) => (event) => {
  //   let value = "";
  //   if (name === "date") {
  //     value = event;
  //   } else {
  //     value = event.target;
  //   }
  //   setFormFields({ ...formFields, error: false, [name]: value });
  // };

  return (
    <>
      <Grid
        container
        display="flex"
        // justifyContent={"flex-end"}
        alignContent={"center"}
        alignItems={"center"}
        // sx={{ height: "90vh", color: "white" }}
        sx={{
          height: "90vh",
          color: "white",
          justifyContent: {
            xs: "center",
            md: "flex-end",
            lg: "flex-end",
            xl: "flex-end",
          },
          paddingRight: {
            xs: 4,
            sm: 5,
            md: 20,
            lg: 20,
            xl: 20,
          },
          paddingLeft: {
            xs: 5,
            sm: 5,
          },
        }}
        // pr={20}
      >
        <Grid
          container
          direction={"column"}
          rowSpacing={2}
          style={{ ...card }}
          xs={12}
          md={4}
          lg={4}
        >
          <Grid item container>
            {!isStep1 && (
              <Grid
                item
                display="flex"
                justifyContent={"flex-start"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <IconButton
                  sx={{ color: "#FFF" }}
                  onClick={() => setIsStep1(true)}
                >
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Grid>
            )}

            <Grid
              item
              display="flex"
              // justifyContent={"flex-end"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant="h5"
                sx={{ ...headingStyle }}
                item
                align="center"
              >
                Create Account
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {isStep1 ? (
              <Step1
                fullname={fullname}
                phoneNumber={phoneNumber}
                email={email}
                gender={gender}
                dob={dob}
                textBoxStyle={textBoxStyle}
                handleChange={handleChange}
                handleTimeChange={handleTimeChange}
                timeValue={timeValue}
                datePickerStyle={datePickerStyle}
              />
            ) : (
              <Step2
                postalAddress={postalAddress}
                residentialAddress={residentailAddress}
                password={password}
                confirmPassword={confirmPassword}
                textBoxStyle={textBoxStyle}
                handleChange={handleChange}
              />
            )}
          </Grid>
          <Grid item>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="MM/DD/YYYY"
                value={timeValue}
                onChange={handleTimeChange}
                renderInput={(params) => <TextField {...params} />}
                name={"dob"}
              />
            </LocalizationProvider> */}
          </Grid>

          <Grid item>
            <Button
              onClick={handleSubmit}
              sx={{ ...buttonStyle }}
              fullWidth
              variant="contained"
            >
              {isStep1 ? <>NEXT</> : <>SUBMIT</>}
            </Button>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUpForm;

// name and surname
// phone
// email
// date of birth
// gender
// postal
// residential
// country
// password
// confirm password

import { Grid, Box, Typography, Button } from "@mui/material";
import React from "react";
import AppHeader from "../../components/appHeader";
import PsycTestPic from "../../assets/brainstorm.png";

const title = {
  fontSize: "60px",
  fontWeight: 700,
  letterSpacing: "2px",
  lineHeight: 1,
  marginBottom: "40px",
};

const psychTestButton = {
  background: "#40A49C",
  color: "#FFF",
  padding: "20px",
  letterSpacing: "2px",
  fontSize: "20px",
};

const desc = {
  fontSize: "20px",
  letterSpacing: "1px",
};

const PersonalityTest = () => {
  return (
    <>
      <AppHeader section={"Personality Test"} />
      <Grid
        container
        direction="column"
        marginTop="60px"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item justifyContent="center">
          <Box
            component="img"
            sx={{
              height: "200px",
              width: "200px",
            }}
            src={PsycTestPic}
          />
        </Grid>
        <Grid item marginTop="50px">
          <Typography align="center" sx={{ ...title }}>
            Be Yourself, Everyone Else Is Taken
          </Typography>
        </Grid>
        <Grid item marginTop="50px">
          <Typography sx={{ ...desc }} align="center">
            We understand that different personalities are suited for different
            careers and before you make a decision to commit to what might be
            our ideal career, how about we help you figure which personality you
            are
          </Typography>
        </Grid>
        <Grid item marginTop="50px">
          <Button sx={{ ...psychTestButton }}>Take the test</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalityTest;

import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/user";

const buttonStyle = {
  background: "#40A49C",
  color: "#FFF",
  borderRaduis: "10px",
  padding: "10px 40px 10px 40px",
};

const textFieldStyle = {
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "#343434",
    fontWeight: "light",
    background: "#F7F8F9",
    border: 0,
  },
  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    // borderColor: "none",
    borderWidth: "0.1px",
  },
};

const AccountSettings = () => {
  const { userData } = useContext(UserContext);
  const [formFields, setFormFields] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const handleUpdateCourse = (event) => {
    setFormFields(formFields);
  };
  return (
    <>
      {(userData[0] && (
        <Grid container direction={"row"} rowSpacing={4}>
          <Grid item container direction={"row"} columnSpacing={4}>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Name"
                onChange={handleChange}
                value={userData[0].name.split(" ").slice(0, -1).join(" ")}
                name="name"
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Surname"
                value={userData[0].name.split(" ").slice(-1).join(" ")}
                disabled
              />
            </Grid>
          </Grid>

          <Grid item container direction={"row"} columnSpacing={4}>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Contacts"
                value={userData[0].phone}
                name="contact"
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Email"
                value={userData[0].email}
                name="email"
                disabled
              />
            </Grid>
          </Grid>

          <Grid item container direction={"row"} columnSpacing={4}>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Username"
                value={userData[0].username}
                name="username"
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Password"
                defaultValue="***********"
                type={"password"}
                disabled
              />
            </Grid>
          </Grid>

          <Grid item container direction={"row"} columnSpacing={4}>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Address"
                defaultValue=" "
                value={userData[0].address}
                name="address"
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{ ...textFieldStyle }}
                id="outlined-disabled"
                label="Location"
                defaultValue="Gaborone"
                value={userData[0].location}
                name="location"
                disabled
              />
            </Grid>
          </Grid>

          <Grid item>
            <Button variant="contained" disabled sx={{ ...buttonStyle }}>
              Update
            </Button>
          </Grid>
        </Grid>
      )) || (
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          display={"flex"}
        >
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};
export default AccountSettings;

import React from "react";
import { Grid } from "@mui/material";
import NewsIconTabs from "../tabs/newsTabs";

const News = () => {
  return (
    <>
      <Grid
        rowSpacing={4}
        // columnSpacing={2}
        container
        direction="row"
        sx={{ display: "flex" }}
      >
        <NewsIconTabs />
      </Grid>
      {/* <Grid container>
        <Grid item>
          <NewsIconTabs />
        </Grid>
        <Grid
          item
          rowSpacing={4}
          columnSpacing={4}
          container
          direction="row"
          sx={{ display: "flex" }}
        >
          <Grid item></Grid>
        </Grid>
      </Grid> */}
    </>
  );
};

export default News;

import {
  Avatar,
  Grid,
  Typography,
  Badge,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import ProfileTabs from "../tabs/profileTabs";
import { UserContext } from "../../contexts/user";

const subheadings = {
  fontWeight: "light",
  fontSize: "16px",
};

const headings = {
  fontWeight: "600",
  fontSize: "20px",
};

const Profile = () => {
  const { userData, setUserData } = useContext(UserContext);

  return (
    <Grid container direction={"row"} columnSpacing={4}>
      <Grid item md={3} lg={3} xs={12}>
        <Grid
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 3px 6px #00000029",
            minHeight: "60vh",
            display: "flex",
          }}
          justifyContent={"center"}
          justifyItems={"center"}
          container
        >
          {(userData[0] && (
            <>
              <Grid
                item
                xs={12}
                justifyContent={"center"}
                justifyItems={"center"}
                display={"flex"}
                marginTop={10}
              >
                <Grid>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={<CameraAltRoundedIcon />}
                  >
                    <Avatar
                      alt="Picture"
                      sx={{
                        height: "150px",
                        width: "150px",
                        //   marginTop: "40px",
                        //   marginBottom: "50px",
                      }}
                      src={userData[0].photoURL}
                    />
                  </Badge>
                </Grid>
              </Grid>
              <Grid item xs={12} direction={"column"} container>
                <Grid item justifyContent={"center"} display={"flex"}>
                  <Typography item sx={{ ...subheadings }}>
                    Full Name
                  </Typography>
                </Grid>
                <Grid item justifyContent={"center"} display={"flex"}>
                  <Typography item sx={{ ...headings }}>
                    {userData[0].name.toString()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} direction={"column"} container>
                <Grid item justifyContent={"center"} display={"flex"}>
                  <Typography item sx={{ ...subheadings }}>
                    Username
                  </Typography>
                </Grid>
                <Grid item justifyContent={"center"} display={"flex"}>
                  <Typography item sx={{ ...headings }}>
                    {/* {userData[0].username.toString()} */}
                    {userData[0].name.toString()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} direction={"column"} container>
                <Grid item justifyContent={"center"} display={"flex"}>
                  <Typography item sx={{ ...subheadings }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item justifyContent={"center"} display={"flex"}>
                  <Typography item sx={{ ...headings }}>
                    {userData[0].email.toString()}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )) || (
            <Grid
              container
              alignContent={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item md={9} lg={9} xs={12}>
        <Grid
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 3px 6px #00000029",
            minHeight: "60vh",
            padding: "20px",
          }}
        >
          <ProfileTabs />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;

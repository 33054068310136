import { Grid } from "@mui/material";
import React from "react";
import CoursesCard from "../cards/courses";

const MyCourses = () => {
  return (
    <Grid>
      <CoursesCard
        availability={"Available"}
        title={"Bachelor of Accounting"}
        enrolled={"120"}
        graduated={"116"}
      />
    </Grid>
  );
};

export default MyCourses;

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const wrapper = {
  borderRadius: "20px",
  color: "#FFF",
  width: "90%",
};

const Team = ({ picture, name, position, company, style, height }) => {
  return (
    <>
      <Grid container direction="column" sx={{ ...wrapper }}>
        <Grid
          item
          sx={{ display: "flex" }}
          //   sx={{
          //     background: `url(${picture})`,
          //     backgroundSize: "100% 100%",
          //     width: "100%",
          //     height: "250px",
          //   }}
        >
          <Box
            component="img"
            sx={{
              // height: { height },
              width: "100%",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              //   0px 3px 6px #00000029
            }}
            src={picture}
          />
        </Grid>
        <Grid
          sx={{
            background: "#40A49C",
            borderBottomRightRadius: "20px",
            borderBottomLeftRadius: "20px",
            paddingLeft: "20px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Grid item>
            <Typography>{name}</Typography>
          </Grid>
          <Grid item direction="row" container>
            <Typography variant="body2">{position}</Typography>
            {/* {" - "} */}
          </Grid>
          <Grid item>
            <Typography variant="body2">{company}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Team;

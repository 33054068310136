import { Grid, Typography } from "@mui/material";
import React from "react";

const style = {
  borderRadius: "15px",
  //   boxShadow: "3px 3px 20px #40A49C80;",
  width: "150px",
  height: "150px",
  padding: "20px",
};

const FilterCard = ({ icon, title, background, color, shadow }) => {
  return (
    <>
      <Grid
        container
        sx={{
          ...style,
          background: `${background}`,
          color: { color },
          boxShadow: `3px 3px 20px ${shadow}`,
        }}
        direction="column"
        
      >
        <Grid item justifyContent="center" display="flex" xs>
          {icon}
        </Grid>
        <Grid item justifyContent="center" display="flex" xs>
          <Typography fontWeight={700}>{title}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterCard;

import React, { useContext, Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import SearchAppBar from "../../components/appBar";
import FilterCard from "../../components/cards/counsellor/filterCard";
import BrushRoundedIcon from "@mui/icons-material/BrushRounded";
import ScienceRoundedIcon from "@mui/icons-material/ScienceRounded";
import CalculateRoundedIcon from "@mui/icons-material/CalculateRounded";
import CounsellorCard from "../../components/cards/counsellor/counsellorCard";
import Data from "../../mockdata/counsilors.json";
import { CounsilorsContext } from "../../contexts/counsellors/index";

const headingStyle = {
  color: "#343434",
  fontWeight: 700,
};

const CareerCounsellor = () => {
  const { counsilorsMap } = useContext(CounsilorsContext);
  // console.log("from context", counsilorsMap);

  return (
    <>
      <Grid container direction={"column"} rowSpacing={4}>
        {/* <Grid item>
          <SearchAppBar />
        </Grid> */}
        <Grid item container columnSpacing={4} rowSpacing={4}>
          <Grid item>
            <FilterCard
              title="Creative"
              background="#40A49C"
              color="#FFF"
              shadow="#40A49C80"
              icon={<BrushRoundedIcon sx={{ fontSize: "5rem" }} />}
            />
          </Grid>
          <Grid item>
            <FilterCard
              title="Medicine"
              background="#FDE84C"
              color="#40A49C"
              shadow="#FDE84C80"
              icon={<ScienceRoundedIcon sx={{ fontSize: "5rem" }} />}
            />
          </Grid>
          <Grid item>
            <FilterCard
              title="Business"
              background="#C6F327"
              color="#343434"
              shadow="#C6F32780"
              icon={<CalculateRoundedIcon sx={{ fontSize: "5rem" }} />}
            />
          </Grid>
          <Grid item>
            <FilterCard
              title="Arts"
              background="#40A49C"
              color="#FFF"
              shadow="#40A49C80"
              icon={<BrushRoundedIcon sx={{ fontSize: "5rem" }} />}
            />
          </Grid>
          <Grid item>
            <FilterCard
              title="Science"
              background="#FDE84C"
              color="#40A49C"
              shadow="#FDE84C80"
              icon={<ScienceRoundedIcon sx={{ fontSize: "5rem" }} />}
            />
          </Grid>
          <Grid item>
            <FilterCard
              title="Accounts"
              background="#C6F327"
              color="#343434"
              shadow="#C6F32780"
              icon={<CalculateRoundedIcon sx={{ fontSize: "5rem" }} />}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography sx={{ ...headingStyle }}>Top Consultants</Typography>
        </Grid>
        <Grid item container direction={"row"}>
          <Fragment>
            {Object.keys(counsilorsMap).map((id) => (
              <Fragment key={id}>
                {counsilorsMap.map((counsilor) => (
                  <Grid md={3} sx={6} xs={6} key={counsilor.name}>
                    <CounsellorCard
                      subject={counsilor.title}
                      time={counsilor.time}
                      name={counsilor.name}
                      picture={counsilor.imageUrl}
                      number={counsilor.phone}
                      email={counsilor.email}
                    />
                  </Grid>
                ))}
              </Fragment>
            ))}
          </Fragment>
        </Grid>
      </Grid>
    </>
  );
};

export default CareerCounsellor;

import { Grid, Typography, Avatar, Divider } from "@mui/material";
import React from "react";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DraftsIcon from "@mui/icons-material/Drafts";
import FooterBG from "../../assets/footerBG.jpg";
import AppButton from "../buttons/appButton";
import PlayStore from "../../assets/playStore.svg";
import AppStore from "../../assets/appStore.svg";
import { letterSpacing } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/logo.png";

const style = {
  background: `url(${FooterBG})`, // 90 opacity
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "#FFF",
  height: "100%",
  backgroundPosition: { md: "0px -400px", lg: "0px -400px" },
  backgroundSize: "cover",
};

const overlay = {
  background: "#40A49C90",
  width: "100%",
  height: "100%",
  padding: "50px",
};

const circleIcon = {
  width: 40,
  height: 40,
  borderRadius: 50,
  borderWidth: 2,
  border: "2px solid #FFF",
  backgroundColor: "#FFFFFF00",
  marginRight: "10px",
};

const footerCopyright = {
  fontWeight: "light",
  letterSpacing: "2px",
};

const footerHeadings = {
  letterSpacing: "2px",
  marginBottom: "20px",
  // fontSize: "20px",
};

const footerParagraph = {
  letterSpacing: "2px",
  fontWeight: "light",
  // fontSize: "16px",
};

const footerLinks = {
  textDecoration: "underline",
  marginBottom: "10px",
};

const link = {
  color: "#FFF",
  textDecoration: "none",
  // fontSize: "20px",
};

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid sx={{ ...style }} container>
        <Grid
          sx={{ ...overlay }}
          container
          direction="row"
          //   columnSpacing={4}
        >
          <Grid container direction="column">
            <Grid container item direction="row" rowSpacing={4}>
              <Grid item xs={12} md={3} sm={12} container direction="column">
                <Grid item mb={2}>
                  <img
                    style={{ cursor: "pointer" }}
                    src={Logo}
                    onClick={() => navigate("/")}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{ ...footerParagraph }}>
                    Course Guide is platform which exists as a solution that
                    answer to the question...
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} sm={12} container direction="column">
                <Grid item>
                  <Typography variant="h6" sx={{ ...footerHeadings }}>
                    Contact us
                  </Typography>
                </Grid>
                <Grid item container>
                  <Grid container direction="row">
                    <DraftsIcon sx={{ color: "#FFF" }} />
                    <Typography variant="body2" sx={{ ...footerParagraph }}>
                      enquiries@courseguide.co.bw
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container sx={{ marginTop: "20px" }}>
                  <Grid container direction="row">
                    <LocalPhoneIcon sx={{ color: "#FFF" }} />
                    <Typography variant="body2" sx={{ ...footerParagraph }}>
                      +267 75 113 225
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container sx={{ marginTop: "20px" }}>
                  <Grid container direction="row">
                    <Avatar sx={{ ...circleIcon }}>
                      <FacebookRoundedIcon sx={{ color: "#FFF" }} />
                    </Avatar>
                    <Avatar sx={{ ...circleIcon }}>
                      <InstagramIcon sx={{ color: "#FFF" }} />
                    </Avatar>
                    <Avatar sx={{ ...circleIcon }}>
                      <TwitterIcon sx={{ color: "#FFF" }} />
                    </Avatar>
                    <Avatar sx={{ ...circleIcon }}>
                      <LinkedInIcon sx={{ color: "#FFF" }} />
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sm={12}
                container
                direction="column"
                rowSpacing={2}
              >
                <Grid item>
                  <Typography variant="h6" sx={{ ...footerHeadings }}>
                    Download the app
                  </Typography>
                </Grid>
                <Grid item>
                  <AppButton
                    name={"Google Play"}
                    desc={"Get it on"}
                    icon={PlayStore}
                  />
                </Grid>
                <Grid item>
                  <AppButton
                    name={"App Store"}
                    desc={"Download on the"}
                    icon={AppStore}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} sm={12} container direction="column">
                <Grid item>
                  <Typography variant="h6" sx={{ ...footerHeadings }}>
                    Already have an account?
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to={"/auth"} style={{ ...link }}>
                    <Typography
                      variant="body2"
                      sx={{ ...footerParagraph, ...footerLinks }}
                    >
                      Log in
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={"/auth"} style={{ ...link }}>
                    <Typography
                      variant="body2"
                      sx={{ ...footerParagraph, ...footerLinks }}
                    >
                      Create account
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={"/privacy-policy"} style={{ ...link }}>
                    <Typography
                      variant="body2"
                      sx={{ ...footerParagraph, ...footerLinks }}
                    >
                      Privacy policy
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={"#"} style={{ ...link }}>
                    <Typography
                      variant="body2"
                      sx={{ ...footerParagraph, ...footerLinks }}
                    >
                      Terms of use
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={"/faq"} style={{ ...link }}>
                    <Typography
                      variant="body2"
                      sx={{ ...footerParagraph, ...footerLinks }}
                    >
                      FAQs
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Divider
                  sx={{
                    border: "1px solid white",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item sx={{ marginTop: "20px" }}>
                <Typography sx={{ ...footerCopyright }}>
                  Copyright 2023. Designed and Developed by Le-Ma Systems
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;

import React from "react";
import { Container } from "@mui/material";
import Tabs from "../../components/tabs/basicTabs";
import SearchAppBar from "../../components/appBar";

const Home = () => {
  return (
    // <Container fixed>
    <>
      {/* <SearchAppBar /> */}
      <Tabs />
      {/* </Container> */}
    </>
  );
};

export default Home;

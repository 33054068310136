import {
  Chip,
  Grid,
  Typography,
  Button,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const style = {
  borderRadius: "10px",
  display: "flex",
  cursor: "pointer",
  width: "90%",
};

const availabilityButton = {
  border: "1px solid #FFF",
  //   padding: "10px",
  color: "#FFF",
};
const buttonStyle = {
  background: "#FFF",
  borderRadius: "5px",
  color: "#40A49C",
  padding: "10px",
  // width: "280px",
  width: "80%",
};

const modalStyle = {
  boxShadow: " 0px 3px 6px #00000029",
  background: "#FFF",
  p: 4,
  borderRadius: "20px",
  // width: "60%",
  width: { xs: "90%", sm: "90%", md: "60%", lg: "60%" },
  height: "80%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 20,
    // width: { xs: 10, sm: 10, lg: 20, md: 20 },
  },
  "&::-webkit-scrollbar-track": {
    // backgroundColor: "orange",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#40A49C",
    borderRadius: 2,
    height: "25%",
  },
};

const NewsCard = ({
  author,
  date,
  description,
  tag,
  background,
  title,
  link,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Grid container direction="column" sx={{ ...style }} onClick={handleOpen}>
        <Grid
          item
          sx={{
            background: `url(${background})`,
            backgroundSize: "cover",
            // width: "280px",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "20px",
            padding: "20px",
          }}
          direction="column"
        >
          <Grid item xs>
            <Grid
              sx={{
                alignItems: "flex-end",
                display: "flex",
                marginBottom: "20px",
                fontSize: "16px",
              }}
            ></Grid>
          </Grid>
          <Grid item xs sx={{ display: "flex" }}>
            <Grid
              sx={{
                alignItems: "flex-end",
                display: "flex",
                marginBottom: "20px",
                fontSize: "16px",
              }}
            >
              <Typography sx={{ color: "#FFF" }}>{title}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs sx={{ color: "#FFF" }} container>
              <DriveFileRenameOutlineIcon item />
              <Typography item fontSize="1rem">
                {author}
              </Typography>
            </Grid>
            <Grid item xs sx={{ color: "#FFF" }} container>
              <CalendarMonthIcon item />
              <Typography item fontSize="1rem">
                {date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflowY: "auto",
        }}
      >
        <Box sx={modalStyle}>
          <Grid container direction={"column"} rowSpacing={4}>
            <Grid item display={"flex"} justifyContent={"center"}>
              <img src={background} width="60%" height="auto" />
            </Grid>
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="justify">
                {description}
              </Typography>
            </Grid>
            <Grid item container>
              <Grid xs item display={"flex"} justifyContent="flex-start">
                <Button
                  sx={{ background: "#40A49C" }}
                  variant="contained"
                  href={link}
                  target="_blank"
                >
                  Read More
                </Button>
              </Grid>
              <Grid
                xs
                container
                item
                display={"flex"}
                justifyContent="flex-end"
                direction={"column"}
              >
                <Grid item>
                  <Typography variant="body2">{author}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">{date}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default NewsCard;

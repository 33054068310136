import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { UserProvider } from "./contexts/user";
import { CounsilorsProvider } from "./contexts/counsellors";
import { UniversitiesProvider } from "./contexts/universities";
import { CoursesProvider } from "./contexts/courses";
import { ThemeProvider } from "@emotion/react";
import theme from "./themes";

const root = ReactDOM.createRoot(
  document.getElementById("root"),
  document.getElementById("viewport")
);
root.render(
  <BrowserRouter>
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <UserProvider>
          {/* <CounsilorsProvider> */}
          {/* <UniversitiesProvider> */}
          {/* <CoursesProvider> */}
          <App />
          {/* </CoursesProvider> */}
          {/* </UniversitiesProvider> */}
          {/* </CounsilorsProvider> */}
        </UserProvider>
      </ThemeProvider>
    </CssBaseline>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { createContext, useState, useEffect, useReducer } from "react";
import {
  createUserDocumnentFromAuth,
  getUserData,
  onAuthStateChangedListener,
} from "../../utils/firebase/firebase";

// actual value to access
export const UserContext = createContext({
  currentUser: "null",
  setCurrentUser: () => "null",
  isSignIn: true,
  setIsSignIn: () => "null",
  isStep1: true,
  setIsStep1: () => "null",
  userData: "null",
  setUserData: () => "null",
});

export const USER_ACTION_TYPE = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
};

const userReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPE.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      };

    default:
      throw new Error(`Unhandled type ${type} in userReducer`);
  }
};

const INITIAL_STATE = {
  currentUser: null,
};

//
export const UserProvider = ({ children }) => {
  // const [currentUser, setCurrentUser] = useState(null);
  // const [ state, dispatch ] = useReducer(userReducer, INITIAL_STATE)
  const [{ currentUser }, dispatch] = useReducer(userReducer, INITIAL_STATE);
  // const { currentUser } = state

  const setCurrentUser = (user) => {
    dispatch({ type: USER_ACTION_TYPE.SET_CURRENT_USER, payload: user });
  };
  const [isSignIn, setIsSignIn] = useState(true);
  const [isStep1, setIsStep1] = useState(true);
  const [userData, setUserData] = useState(null);
  const [localData, setLocalData] = useState(null);

  // allows any childre to access value from provider
  const value = {
    currentUser,
    setCurrentUser,
    isSignIn,
    setIsSignIn,
    isStep1,
    setIsStep1,
    userData,
    setUserData,
  };

  useEffect(() => {
    const getUserInformation = async () => {
      const userInfo = await getUserData(currentUser && currentUser.uid); // currentUser.uid

      setUserData(userInfo);
      // localStorage.setItem("userData", JSON.stringify(userInfo));
    };

    getUserInformation();
  }, [currentUser]);

  // useEffect(() => {
  //   localStorage.setItem("currentUser", JSON.stringify(currentUser));
  // }, []);

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("currentUser"));
  //   if (user) {
  //     setCurrentUser(user);
  //   }
  // }, []);

  // useEffect(() => {
  //   const getUserInformation = async () => {
  //     const userInfo = await getUserData(currentUser && currentUser.uid); // currentUser.uid
  //     setLocalData(userInfo);
  //     localStorage.setItem("userData", JSON.stringify(userInfo));
  //   };
  //   getUserInformation();
  // }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (user) {
      setUserData(user);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        createUserDocumnentFromAuth(user);
      }
      setCurrentUser(user);
    });
    return unsubscribe;
    // onAuthStateChanged(currentUser, setCurrentUser)
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

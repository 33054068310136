import React from "react";
import { Container, Grid } from "@mui/material";
import Landing from "../../components/landingPage";

const LandingPage = () => {
  return (
    <>
      <Landing />
    </>
  );
};

export default LandingPage;

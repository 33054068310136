import React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";
const Step2 = ({
  postalAddress,
  residentialAddress,
  password,
  confirmPassword,
  handleChange,
  textBoxStyle,
}) => {
  return (
    <Grid>
      <Grid item mt={2}>
        <TextField
          variant="filled"
          sx={{ ...textBoxStyle }}
          fullWidth
          label={"Postal Address"}
          onChange={handleChange}
          name={"PostalAddress"}
          value={postalAddress}
        />
      </Grid>
      <Grid item mt={2}>
        <TextField
          sx={{ ...textBoxStyle }}
          fullWidth
          variant="filled"
          label={"Residentail Address"}
          onChange={handleChange}
          name={"residentialAddress"}
          value={residentialAddress}
        />
      </Grid>
      <Grid item mt={2}>
        <TextField
          sx={{ ...textBoxStyle }}
          fullWidth
          variant="filled"
          label={"Password"}
          name={"password"}
          value={password}
          onChange={handleChange}
        />
      </Grid>
      <Grid item mt={2}>
        <TextField
          sx={{ ...textBoxStyle }}
          fullWidth
          variant="filled"
          label={"Confirm Password"}
          name={"confirmPassword"}
          value={confirmPassword}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default Step2;

import React, { useContext, useEffect } from "react";
import Profile from "../../components/profile";
import AppHeader from "../../components/appHeader";

const MyProfile = () => {
  return (
    <>
      <AppHeader section={"Profile"} />

      <Profile />
    </>
  );
};

export default MyProfile;

import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import {
  createUserDocumnentFromAuth,
  signInWithGooglePopup,
  signInAuthUserWithEmailAndPassword,
} from "../../utils/firebase/firebase";
import { UserContext } from "../../contexts/user";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

const defaultformFields = {
  email: "",
  password: "",
};

const card = {
  background: "#40A49C",
  borderRadius: "10px",
  height: { xs: "70%", sm: "70%", md: "75%", lg: "75%", xl: "75%" },
  // width: "25%",
  // padding: "40px",
  padding: 20,
  color: "#FFF",
};

const textBoxStyle = {
  background: "#FFF",
  borderRadius: "10px",
  // ".css-10zro85-MuiInputBase-root-MuiFilledInput-root:before": {
  "& .MuiFilledInput-root:before": {
    borderBottom: "none",
  },
  // ".css-10zro85-MuiInputBase-root-MuiFilledInput-root:after": {
  "& .MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  // "& .MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
  //   borderBottom: "none",
  // },
  "& .MuiTextField-root & .MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-input:-webkit-autofill": {
    borderRadius: "10px",
  },
  // "& .MuiFilledInput-root:hover:not": {
  //   "& > fieldset": {
  //     borderColor: "orange",
  //   },
  //   borderBottom: "2px solid purple",
  // },
  "& .MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
    borderBottom: "none",
  },
};

const buttonStyle = {
  background: "#C6F327",
  color: "#FFFFFF",
  height: "100%",
};

const headingStyle = {
  // fontSize: "30px",
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultformFields);

  // Distructure fields for use later, probably
  const { email, password } = formFields;

  const { setCurrentUser, isSignIn, setIsSignIn } = useContext(UserContext);

  const resetFormFields = () => {
    setFormFields(defaultformFields);
  };

  const signInWithGoogle = async () => {
    await signInWithGooglePopup();
    // setCurrentUser(user);
    navigate("/dashboard");
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await signInAuthUserWithEmailAndPassword(email, password);
      // setCurrentUser(user);
      resetFormFields();
      navigate("/dashboard");
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          alert("Incorrect Credentials");
          break;
        case "auth/user-not-found":
          alert("No email associated with user");
          break;
        default:
          console.log(error);
      }
    }
  };

  const register = () => {
    setIsSignIn(false);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };
  return (
    <>
      {/* <form
        onSubmit={() => {
          handleSubmit();
        }}
      > */}
      <Grid
        container
        display="flex"
        // justifyContent={"flex-end"}
        alignContent={"center"}
        alignItems={"center"}
        sx={{
          height: "90vh",
          color: "white",
          justifyContent: {
            xs: "center",
            md: "flex-end",
            lg: "flex-end",
            xl: "flex-end",
          },
          paddingRight: {
            xs: 4,
            sm: 5,
            md: 20,
            lg: 20,
            xl: 20,
          },
          paddingLeft: {
            xs: 5,
            sm: 5,
          },
        }}
        // pr={20}
      >
        <Grid
          container
          direction={"column"}
          rowSpacing={2}
          style={{ ...card }}
          xs={12}
          md={4}
          lg={4}
        >
          <Grid item>
            <Typography
              variant="h5"
              sx={{ ...headingStyle }}
              item
              align="center"
            >
              Login
            </Typography>
            <Typography variant="subtitle2" item align="center">
              Welcome To Course Guide
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="filled"
              sx={{ ...textBoxStyle }}
              fullWidth
              label={"Email"}
              name="email"
              onChange={handleChange}
              value={email}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ ...textBoxStyle }}
              fullWidth
              variant="filled"
              label={"Password"}
              name="password"
              onChange={handleChange}
              value={password}
              type="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              sx={{ ...buttonStyle }}
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >
              Login
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="google"
              // sx={{ ...buttonStyle }}
              fullWidth
              variant="contained"
              onClick={signInWithGoogle}
            >
              Login with Google
            </Button>
          </Grid>
          <Grid item align="center">
            <Typography variant="body2">Forgot Password?</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={"light"} align="center">
              Don't have an account yet?
              <span
                style={{
                  color: "#FDE84C",
                  textDecoration: "underline",
                  textDecorationColor: "#FDE84C",
                  cursor: "pointer",
                }}
                onClick={() => setIsSignIn(false)}
              >
                Register
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* </form> */}

      {/* <h1>Already have an account? Sign up with email and password</h1>
      <form
        onSubmit={() => {
          handleSubmit();
        }}
      >
        <label>Email</label>
        <input
          type="email"
          onChange={handleChange}
          name="email"
          value={email}
        />
        <label>Password</label>
        <input
          type="password"
          onChange={handleChange}
          name="password"
          value={password}
        />

        <button type="submit">Sign In</button>
        <button buttontype="google" type="button" onClick={signInWithGoogle}>
          Sign In With Google
        </button>
      </form> */}
    </>
  );
};

export default SignInForm;

import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { border, borderRadius } from "@mui/system";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  width: "90%",
  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
  color: "#FFF",
  textTransform: "uppercase",
  letterSpacing: "2px",
  // fontSize: "20px",
  // ".css-1ws8119-MuiDivider-root::before, .css-1ws8119-MuiDivider-root::after": {
  //   borderTop: "3px solid white",
  // },
  "&.MuiDivider-root:before": {
    borderTop: "3px solid white",
  },
}));

const style = {
  background: "#40A49C",
  borderRadius: "5px",
  width: "100%",
  height: "64px",
  marginBottom: "40px",
};

const dividerStyle = {
  //   background: "#FFF",
  //   border: "5px solid red",
  //   height: "1px",
  borderTop: "1px solid red",
  width: "100%",
};

const AppHeader = ({ section, width }) => {
  return (
    <Grid
      sx={{ ...style, width: `${width}` }}
      direction="row"
      container
      justifyContent="center"
      alignItems="center"
    >
      <Root>
        <Divider
          sx={{
            "&::before, &::after": {
              // borderColor: "#FFF",
              borderTop: "3px solid white",
            },
          }}
        >
          <Typography
            sx={{
              paddingLeft: "30px",
              paddingRight: "30px",
              letterSpacing: "2px",
              fontWeight: 600,
              fontSize: "25px",
            }}
          >
            {section}
          </Typography>
        </Divider>
      </Root>
    </Grid>
  );
};

export default AppHeader;

import {
  Grid,
  Typography,
  Button,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import React from "react";

const style = {
  borderRadius: "10px",
  display: "flex",
  // width: "100%",
  width: "80%",
};

const backgroundPic = {};

const buttonStyle = {
  background: "#FFF",
  borderRadius: "5px",
  color: "#40A49C",
  padding: "10px",
  // width: "280px",
  width: "80%",
};

// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 400,
//   // bgcolor: "background.paper",
//   // border: "2px solid #000",
//   boxShadow: " 0px 3px 6px #00000029",
//   background: "#FFF",
//   p: 4,
//   borderRadius: "20px",
//   overflowY: "auto",
// };

const modalStyle = {
  boxShadow: " 0px 3px 6px #00000029",
  background: "#FFF",
  p: 4,
  borderRadius: "20px",
  width: { xs: "90%", sm: "90%", md: "60%", lg: "60%" },
  height: "80%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    // width: { xs: 10, sm: 10, lg: 20, md: 20 },
    width: 20,
  },
  "&::-webkit-scrollbar-track": {
    // backgroundColor: "orange",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#40A49C",
    borderRadius: 2,
    height: "25%",
  },
};

const ScholarshipCard = ({ background, title, link, description }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Grid container direction="column" sx={{ ...style }}>
        <Grid
          item
          sx={{
            background: `url(${background})`,
            backgroundSize: "cover",
            // width: "280px",
            // width: "100%",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <Grid
            sx={{
              alignItems: "flex-end",
              display: "flex",
              marginBottom: "20px",
              fontSize: "16px",
            }}
          >
            <Typography sx={{ color: "#FFF" }} align="center">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            // width: "280px",
            background: "#40A49C",
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpen}
            sx={{ ...buttonStyle }}
          >
            Apply now
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflowY: "auto",
        }}
      >
        <Box sx={modalStyle}>
          <Grid container direction={"column"} rowSpacing={4}>
            <Grid item display={"flex"} justifyContent={"center"}>
              {(background && (
                <img src={background} width="60%" height="auto" />
              )) || <CircularProgress />}
            </Grid>
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="justify">
                {description}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{ background: "#40A49C" }}
                variant="contained"
                href={link}
                target="_blank"
              >
                Click Here To Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ScholarshipCard;

import React from "react";
import IconTabs from "../tabs/iconTabs";
import { Grid } from "@mui/material";

const Courses = () => {
  return (
    <>
      <Grid
        rowSpacing={2}
        columnSpacing={2}
        container
        direction="row"
        sx={{ display: "flex" }}
      >
        <IconTabs />
      </Grid>
      {/* <Grid container>
        <Grid item>
          <IconTabs />
        </Grid> */}
      {/* <Grid
          item
          rowSpacing={4}
          columnSpacing={4}
          container
          direction="row"
          sx={{ display: "flex" }}
        >
          <Grid item></Grid>
        </Grid> */}
      {/* </Grid> */}
    </>
  );
};

export default Courses;

import React from "react";
import { Grid, Button } from "@mui/material";
import AppHeader from "../../components/appHeader";
import Logo from "../../assets/logos/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/footer";

const navBar = {
  height: "10vh",
  background: "#40A49C",
  width: "100%",
  // padding: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  color: "#FFF",
  marginBottom: "40px",
};

const logInButtonStyle = {
  borderRadius: "10px",
  padding: "10px",
  color: "#40A49C",
  background: "#FFF",
  width: "200px",
  letterSpacing: "0.1rem",
};

const FAQPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container direction={"column"}>
        <Grid container sx={{ ...navBar }} item direction={"row"}>
          <Grid
            xs
            item
            display={"flex"}
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <img
              src={Logo}
              // href="/"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              height="60%"
              width="auto"
            />
          </Grid>
          <Grid
            xs
            item
            display={"flex"}
            container
            justifyContent={"flex-end"}
            justifyItems={"center"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{
              pr: { xs: "4", sm: "5", md: "20", lg: "20", xl: "20" },
            }}
            // alignContent={"center"}
          >
            <Button
              href="/auth"
              sx={{ ...logInButtonStyle }}
              fullWidth
              variant="contained"
            >
              Login/Register
            </Button>
          </Grid>
        </Grid>
        <Grid item justifyContent={"center"} display={"flex"}>
          <AppHeader width={"80%"} section={"Frequently Asked Question"} />
        </Grid>
        <Grid
          item
          sx={{ width: "100%" }}
          justifyContent={"center"}
          display={"flex"}
        >
          <Grid item>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>How much does this course cost?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Any recommendation on which course I can take?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel3a-header"
              >
                <Typography>
                  How do I get more information on this course?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel4a-header"
              >
                <Typography>Is anyone available to chat?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel5a-header"
              >
                <Typography>
                  May I talk to anyone for academic advice?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel6a-header"
              >
                <Typography>
                  May I have the link to the institutions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid item container sx={{ marginTop: "100px" }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default FAQPage;

import React, { useContext } from "react";
import SignUpForm from "../../components/signupForm";
import SignInForm from "../../components/signinForm";
import FamilyBG from "../../assets/family.png";
import { Grid, Button } from "@mui/material";
import { UserContext } from "../../contexts/user";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/logo.png";
const style = {
  background: `url(${FamilyBG})`,
  backgroundSize: "cover",
  // height: "91.3vh",
  height: "90vh",
  minHeight: "90vh",
};

const navBar = {
  height: "10vh",
  background: "#40A49C",
  width: "100%",
  // padding: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  color: "#FFF",
};

const Authentication = () => {
  const { isSignIn } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <>
      <Grid container sx={{ ...navBar }} item direction={"row"}>
        <Grid
          xs
          item
          display={"flex"}
          container
          justifyContent={"flex-start"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <img
            src={Logo}
            href="/"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
            height="60%"
            width="auto"
          />
        </Grid>
        <Grid
          xs
          item
          display={"flex"}
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          columnSpacing={{ xs: "0", sm: "0", lg: "3", md: "3", xl: "3" }}
          sx={{
            pr: { xs: "4", sm: "5", md: "20", lg: "20", xl: "20" },
          }}
          // alignContent={"center"}
        >
          <Grid item>
            <FacebookRoundedIcon />
          </Grid>
          <Grid item>
            <WhatsAppIcon />
          </Grid>
          <Grid item>
            <TwitterIcon />
          </Grid>
          <Grid item>
            <YouTubeIcon />
          </Grid>
          <Grid item>
            <InstagramIcon />
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ ...style }}>
        {isSignIn ? <SignInForm /> : <SignUpForm />}
      </Grid>
    </>
  );
};

export default Authentication;

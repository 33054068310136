import React, { useContext, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography,
  Modal,
  CircularProgress,
  TextField,
  Popover,
  Snackbar,
  IconButton,
} from "@mui/material";
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import BG from "../../../../assets/counsillorpic.jpg";
import {
  addAppointment,
  addBookingMail,
  Axios,
  db,
} from "../../../../utils/firebase/firebase";
import { UserContext } from "../../../../contexts/user";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";

const style = {
  borderRadius: "15px",
  boxShadow: "3px 3px 20px #0000001F;",
  width: "350px",
  height: "380px",
  padding: "20px",
  background: "#FFF",
};

const nameStyle = {
  color: "#343434",
  fontWeight: 600,
  fontSize: "20px",
};

const buttonStyle = {
  background: "#40A49C",
  borderRadius: "5px",
  //   padding: "10px",
  color: "#FFF",
  padding: "10px 20px 10px 20px",
};

const modalStyle = {
  boxShadow: " 0px 3px 6px #00000029",
  background: "#FFF",
  p: 4,
  borderRadius: "20px",
  width: { xs: "90%", sm: "90%", md: "60%", lg: "60%" },
  height: "80%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    // width: { xs: 10, sm: 10, lg: 20, md: 20 },
    width: 20,
  },
  "&::-webkit-scrollbar-track": {
    // backgroundColor: "orange",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#40A49C",
    borderRadius: 2,
    height: "25%",
  },
};

const defaultformFields = {
  date: "",
  consultant: "",
};

const CounsellorCard = ({ picture, subject, number, time, name }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formFields, setFormFields] = useState(defaultformFields);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [value, setValue] = React.useState(dayjs());
  const [emailData, setEmailData] = useState({
    consultant: "formFields.consultant",
    date: "formFields.date",
    email: "userData[0].email",
    name: "userData[0].name",
  });

  const { userData } = useContext(UserContext);

  console.log("uuuuuuuuuu", userData[0] && userData[0].email);

  // [0].email

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  const handleChange = (newValue) => {
    setValue(newValue);
    setFormFields({
      ...formFields,
      date: dayjs(value).format("DD-MM-YYYY HH:mm:ss"),
      consultant: name,
      // user: userData,
    });
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    handleClose();
  };

  const sendEmail = () => {
    Axios.post(
      "https://us-central1-course-guide-2022.cloudfunctions.net/sendmail",
      // emailData
      {
        // user: userData,
        consultant: formFields.consultant,
        date: formFields.date,
        email: userData[0].email,
        name: userData[0].name,
      }
    )
      .then((res) => {
        addBookingMail({
          consultant: formFields.consultant,
          date: formFields.date,
          email: userData[0].email,
          name: userData[0].name,
        });
        // db.collection("counsillorMails").add({
        //   user: userData,
        //   consultant: formFields.consultant,
        //   date: formFields.date,
        //   email: userData[0].email,
        //   name: userData[0].name,
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    sendEmail();
    setEmailData({});
  };

  return (
    <>
      <Grid
        container
        direction={"column"}
        sx={{ ...style }}
        alignItems="center"
        rowSpacing={2}
      >
        <Grid item>
          <Avatar
            alt="Counsellor"
            sx={{
              height: "150px",
              width: "150px",
              //   marginTop: "40px",
              //   marginBottom: "50px",
            }}
            src={picture}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ ...nameStyle }}>{name}</Typography>
        </Grid>
        <Grid
          item
          container
          direction={"row"}
          columnSpacing={2}
          alignItems={"center"}
          justifyItems={"center"}
          sx={{ width: "80%" }}
        >
          <Grid item xs={4}>
            <Typography>{subject}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{time}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Avatar
              sx={{
                bgcolor: "#C6F327",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              variant="square"
            >
              <PhoneEnabledRoundedIcon onClick={handleClick} />
              <Popover
                id={id}
                open={openPopOver}
                anchorEl={anchorEl}
                onClose={handleClosePopOver}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Typography sx={{ p: 2 }}>{number}</Typography>
              </Popover>
            </Avatar>
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={handleOpen} sx={{ ...buttonStyle }}>
            Book an appointment
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflowY: "auto",
        }}
      >
        <Box sx={modalStyle}>
          <Grid container direction={"column"} rowSpacing={4} p={10}>
            <Grid item display={"flex"} justifyContent={"center"}>
              <img
                src={BG}
                // style={{ cursor: "pointer" }}
                // onClick={() => navigate("/")}
                height="300px"
                width="auto"
              />
            </Grid>
            <Grid item display={"flex"} justifyContent={"center"}>
              <Typography variant="subtitle1">
                Choose date and time for appointment
              </Typography>
            </Grid>
            <Grid item display={"flex"} justifyContent={"center"}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  // label="Date&Time picker"
                  name="date"
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item display={"flex"} justifyContent={"center"}>
              <Button
                sx={{
                  backgroundColor: "#40A49C",
                  color: "#FFF",
                  borderRadius: "10px",
                  padding: "20px",
                  width: "200px",
                }}
                onClick={() => {
                  // addAppointment(formFields);
                  // sendEmail();
                  // setEmailData({
                  //   consultant: formFields.consultant,
                  //   date: formFields.date,
                  //   email: userData[0].email,
                  //   name: userData[0].name,
                  // });
                  // console.log("form fields", formFields);
                  handleSubmit();
                  handleOpenSnackBar();
                }}
              >
                Book
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleCloseSnackBar}
        message={"Appointment has been booked."}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleCloseSnackBar();
                handleClose();
              }}
            >
              <DisabledByDefaultRoundedIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default CounsellorCard;

import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, useNavigate, Link, NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import PsychologyAltRoundedIcon from "@mui/icons-material/PsychologyAltRounded";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Avatar, MenuList } from "@mui/material";
import Logo from "../../assets/logos/logo.png";
import { signOutUser } from "../../utils/firebase/firebase";
import { UserContext } from "../../contexts/user";
import { Menu, MenuItem } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  marginBottom: "40px",
}));

const menuItemStyle = {
  height: "50px",
  // color: "#FFF",
  // "&.Mui-disabled": {
  //   backgroundColor: "lightgrey",
  // },
  // "&.Mui-selected": {
  //   color: "red",
  // },
  // "&.Mui-active": {
  //   color: "red",
  // },
  // color: "#FFF",
  // textDecoration: "none",
};

const link = {
  color: "#FFF",
  textDecoration: "none",
  // fontSize: "20px",
};

const linkStyle = {
  textDecoration: "none",
  color: "#FFF",
  // "&:focus, &:hover, &:visited, &:link, &:active": {
  "&:hover": {
    color: "red",
  },
};

const drawerWidth = 240;

function Layout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { currentUser, userData } = useContext(UserContext);

  // console.log("layout", userData);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <DrawerHeader>
        <img
          style={{ cursor: "pointer" }}
          src={Logo}
          onClick={() => navigate("/dashboard")}
        />
      </DrawerHeader>
      <DrawerHeader>
        {userData[0] ? (
          <Avatar
            sx={{
              height: "8rem",
              width: "8rem",
              // marginTop: "40px",
              // marginBottom: "40px",
            }}
            src={userData[0].photoURL}
          />
        ) : (
          <></>
        )}
        {/* {(userData && (
          <Avatar
            sx={{
              height: "8rem",
              width: "8rem",
              // marginTop: "40px",
              // marginBottom: "40px",
            }}
            src={userData[0].photoURL || ""}
          />
        )) || (
          <Avatar
            sx={{
              height: "8rem",
              width: "8rem",
              // marginTop: "40px",
              // marginBottom: "40px",
            }}
          />
        )} */}
      </DrawerHeader>

      {/* <MenuItem component={Link} href="/">
        Home
      </MenuItem>
      <MenuItem component={Link} href="/">
        Home
      </MenuItem> */}

      <MenuList>
        <NavLink
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "light" : "bold",
              color: isActive ? "#FDE84C" : "#FFF",
              textDecoration: "none",
            };
          }}
          to="/dashboard"
        >
          <MenuItem sx={{ ...menuItemStyle }}>
            <ListItemIcon sx={{ color: "#FFF" }}>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </MenuItem>
        </NavLink>
        <NavLink
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "light" : "bold",
              color: isActive ? "#FDE84C" : "#FFF",
              textDecoration: "none",
            };
          }}
          to="/universities"
        >
          <MenuItem
            // component={Link}
            // href="/universities"
            sx={{ ...menuItemStyle }}
          >
            <ListItemIcon sx={{ color: "#FFF" }}>
              <SchoolRoundedIcon />
            </ListItemIcon>
            <ListItemText>Universities</ListItemText>
          </MenuItem>
        </NavLink>
        <NavLink
          to="/personality-test"
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "light" : "bold",
              color: isActive ? "#FDE84C" : "#FFF",
              textDecoration: "none",
            };
          }}
        >
          <MenuItem
            // component={Link}
            // href="/personality-test"
            sx={{ ...menuItemStyle }}
          >
            <ListItemIcon sx={{ color: "#FFF" }}>
              <PsychologyAltRoundedIcon />
            </ListItemIcon>
            <ListItemText>Personality Test</ListItemText>
          </MenuItem>
        </NavLink>
        <NavLink
          to="/career-counsellor"
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "light" : "bold",
              color: isActive ? "#FDE84C" : "#FFF",
              textDecoration: "none",
            };
          }}
        >
          <MenuItem
            // component={Link}
            // href="/career-counsellor"
            sx={{ ...menuItemStyle }}
          >
            <ListItemIcon sx={{ color: "#FFF" }}>
              <HeadsetMicRoundedIcon />
            </ListItemIcon>
            <ListItemText>Career Counsellor</ListItemText>
          </MenuItem>
        </NavLink>
        <NavLink
          to="/my-profile"
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "light" : "bold",
              color: isActive ? "#FDE84C" : "#FFF",
              textDecoration: "none",
            };
          }}
        >
          <MenuItem
            // component={Link}
            // href="/my-profile"
            sx={{ ...menuItemStyle }}
          >
            <ListItemIcon sx={{ color: "#FFF" }}>
              <ManageAccountsRoundedIcon />
            </ListItemIcon>
            <ListItemText>My Profile</ListItemText>
          </MenuItem>
        </NavLink>
        <NavLink
          to="/"
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "light" : "bold",
              color: isActive ? "#FDE84C" : "#FFF",
              textDecoration: "none",
            };
          }}
        >
          <MenuItem
            // component={Link}
            onClick={() => {
              signOutUser();
              localStorage.removeItem("currentUser");
            }}
            sx={{ ...menuItemStyle }}
            // href="/landing"
          >
            <ListItemIcon sx={{ color: "#FFF" }}>
              <LogoutRoundedIcon />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </MenuItem>
        </NavLink>
      </MenuList>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          //   width: { sm: `calc(100% - ${drawerWidth}px)` },
          width: { sm: `calc(100% - 264px)` },
          //   width: { sm: `calc(100% - ${drawerWidth}px)`, md: "85%", lg: "85%" },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          background: "#F2F3F7",
          color: "#A1A1A1",
          border: "none",
          boxShadow: "none",
          //   marginTop: "100px",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
              background: "#40A49C",
              color: "#FFF",
              borderRadius: "0px 50px 0px 0px",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
              background: "#40A49C",
              color: "#FFF",
              borderRadius: "0px 50px 0px 0px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Layout;

import React, { useContext, useEffect, Fragment, useState } from "react";
import CoursesCard from "../cards/courses";
import { CoursesContext } from "../../contexts/courses";
import { Grid, CircularProgress } from "@mui/material";
import EmptyState from "../emptyState";

const CoursesCategory = (category) => {
  const { coursesData, setCourseCategory, setCoursesData, setCategory } =
    useContext(CoursesContext);
  const [loading, setLoading] = useState(false);

  var categoryCourses = [];

  useEffect(() => {
    setCategory(category.category);
    // if (coursesData) {
    //   categoryCourses = Object.values(coursesData).filter((course) => {
    //     return course.category === category;
    //   });
    //   console.log("courses category ", categoryCourses);
    // }
  }, []);

  // const filtered2 = employees.filter(employee => {
  //   return employee.country === 'Canada' && employee.id === 3;
  // });

  if (loading && !coursesData) {
    return <CircularProgress />;
  }

  if (coursesData.length === 0 || !coursesData) {
    // return <EmptyState />;
    return <CircularProgress />;
  }

  return (
    <>
      <Grid
        rowSpacing={4}
        // columnSpacing={2}
        container
        direction="row"
        sx={{ display: "flex" }}
      >
        {/* {Object.keys(coursesData || {}).map((id) => (
        <Fragment key={id}> */}
        {loading && <CircularProgress />}

        {Object.values(coursesData).map((course) => (
          <Grid item xs={12} md={3} lg={3} xl={3} key={course.id}>
            <CoursesCard
              availability={course.state}
              title={course.title}
              enrolled={course.enrolled}
              graduated={course.graduated}
              background={course.imageUrl}
              description={course.description}
            />
          </Grid>
          //   ))}
          // </Fragment>
        ))}
      </Grid>
    </>
  );
};

export default CoursesCategory;

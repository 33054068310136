import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  CircularProgress,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
  ListItemAvatar,
  Divider,
  Paper,
  InputBase,
  Snackbar,
  // MenuIcon,
  // SearchIcon,
  // DirectionsIcon,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useContext, useState } from "react";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { UniversitiesContext } from "../../../contexts/universities";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { UserContext } from "../../../contexts/user";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import dayjs, { Dayjs } from "dayjs";
import { addAdvice } from "../../../utils/firebase/firebase";

const style = {
  boxShadow: "3px 3px 20px #0000001A",
  borderRadius: "15px",
  padding: "20px",
};

const buttonProgramme = {
  height: "50px",
  background: "#40A49C",
  color: "#FFF",
  borderRadius: "5px",
  textTransform: "capitalize",
  padding: "20px",
};

const buttonFormn = {
  height: "50px",
  background: "#FFF",
  color: "#40A49C",
  borderRadius: "5px",
  border: "1px solid #40A49C",
  textTransform: "capitalize",
  padding: "20px",
};

const universityName = {
  fontWeight: 500,
  fontSize: "18px",
};

const universityDesc = {
  fontWeight: "light",
};

const modalStyle = {
  boxShadow: " 0px 3px 6px #00000029",
  background: "#FFF",
  p: 4,
  borderRadius: "20px",
  width: { xs: "90%", sm: "90%", md: "60%", lg: "60%" },
  height: "80%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    // width: { xs: 10, sm: 10, lg: 20, md: 20 },
    width: 20,
  },
  "&::-webkit-scrollbar-track": {
    // backgroundColor: "orange",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#40A49C",
    borderRadius: 2,
    height: "25%",
  },
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  border: 0,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  marginBottom: 10,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        color="#FFF"
        sx={{ fontSize: "0.9rem", color: "#FFF !important" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === "dark"
  //     ? "rgba(255, 255, 255, .05)"
  //     : "rgba(0, 0, 0, .03)",
  color: "#FFFFFF",
  textAlign: "center",
  backgroundColor: "#40A49C",
  borderRadius: "20px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "#FFF",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#FFF",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const defaultformFields = {
  comment: "",
  date: "",
  status: "pending",
  user: {},
};
const UniversitiesCard = ({ name, desc, logo, programs, advice, id }) => {
  const { userData } = useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});

  const handleOpen = () => {
    setOpen(true);
    setUniversity(id);
  };
  const handleClose = () => setOpen(false);

  const [openCourse, setOpenCourse] = React.useState(false);
  const handleOpenCourse = () => setOpenCourse(true);
  const handleCloseCourse = () => setOpenCourse(false);

  const [openAdvice, setOpenAdvice] = useState(false);
  const [formFields, setFormFields] = useState(defaultformFields);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleOpenAdvice = () => {
    setOpenAdvice(true);
    setUniversity(id);
    console.log("id", id);
  };
  const handleCloseAdvice = () => setOpenAdvice(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({
      ...formFields,
      [name]: value,
      date: dayjs().format("DD-MM-YYYY"),
      user: userData[0],
    });
  };

  const {
    universitiesData,
    university,
    setUniversity,
    universityCourses,
    setUniversityCourses,
    universityAdvice,
    setUniversityAdvice,
  } = useContext(UniversitiesContext);

  return (
    <>
      <Grid container direction="column" sx={{ ...style }}>
        <Grid item xs display="flex" justifyContent="center">
          <Box
            component="img"
            sx={{
              height: "200px",
              width: "200px",
              // maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
            }}
            src={logo}
          />
        </Grid>
        <Grid item xs direction="column">
          <Grid item>
            <Typography sx={{ ...universityName }}>{name}</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ ...universityDesc }}>
              {desc.substr(0, 250) + "..."}
            </Typography>
          </Grid>
          <Grid item container direction="row" sx={{ marginTop: "20px" }}>
            <Grid item xs>
              <Button onClick={handleOpen} sx={{ ...buttonProgramme }}>
                Programmes
              </Button>
            </Grid>
            <Grid item xs>
              <Button onClick={handleOpenAdvice} sx={{ ...buttonFormn }}>
                Advice Forums
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflowY: "auto",
        }}
      >
        <Box sx={modalStyle}>
          <Grid container direction={"column"} rowSpacing={4}>
            <Grid item>
              {/* University Of Botswana */}
              {/* Boitekanelo College */}
              {/* GUC */}
              {(() => {
                switch (name) {
                  case "University Of Botswana":
                    return (
                      <>
                        {/* Faculty of Business */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF BUSINESS
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.faculty == "Business"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Education */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF EDUCATION
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.faculty == "Education"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Engineering and Technology */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF ENGINEERING AND TECHNOLOGY
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.faculty ==
                                    "Engineering and Technology"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        {/* Health Sciences */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF HEALTH SCIENCES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.faculty == "Health Sciences"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        {/* Humanities */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF HUMANITIES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.faculty == "Humanities"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        {/* Medicine */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF MEDICINE
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.faculty == "Medicine"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        {/* Science */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF SCIENCES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter((course) => course.faculty == "Science")
                                .map((course) => {
                                  return (
                                    <>
                                      {course.length == 0 ? (
                                        <Typography variant="subtitle1">
                                          No Courses Available
                                        </Typography>
                                      ) : (
                                        <>
                                          {course && (
                                            <List>
                                              <ListItem disablePadding>
                                                <ListItemButton
                                                  onClick={() => {
                                                    setSelectedCourse(course);
                                                    handleOpenCourse();
                                                  }}
                                                >
                                                  <ListItemIcon>
                                                    <VisibilityRoundedIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary={course.title}
                                                  />
                                                </ListItemButton>
                                              </ListItem>
                                            </List>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        {/* Socail Sciences */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              FACULTY OF SOCIAL SCIENCES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {Object.values(universityCourses)
                              .filter(
                                (course) => course.faculty == "Social Science"
                              )
                              .map((course) => {
                                return (
                                  <>
                                    {/* {console.log(course)} */}
                                    {(course && (
                                      <List>
                                        <ListItem disablePadding>
                                          <ListItemButton
                                            onClick={() => {
                                              setSelectedCourse(course);
                                              handleOpenCourse();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <VisibilityRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={course.title}
                                            />
                                          </ListItemButton>
                                        </ListItem>
                                      </List>
                                    )) || (
                                      <Typography variant="subtitle1">
                                        No Courses Available
                                      </Typography>
                                    )}
                                  </>
                                );
                              })}
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                  case "GUC":
                    return (
                      <>
                        {/* Using courseType field for GUC */}
                        {/* Degree */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              BACHELORS PROGRAMMES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.courseType == "Degree"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Diploma  */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DIPLOMA PROGRAMMES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.courseType == "Diploma"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Masters */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              MASTERS PROGRAMMES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.courseType == "Masters"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Certificate */}

                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              CERTIFICATE PROGRAMMES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.courseType == "Certificate"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                  case "Boitekanelo College":
                    return (
                      <>
                        {/* Boitekanelo uses department field */}
                        {/* Occupational Health */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF OCCUPATIONAL HEALTH AND SAFETY
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.department ==
                                    "Occupational Health and Safety"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Healtcare Management */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF HEALTHCARE MANAGEMENT
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.department == "Healthcare Management"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Health Promotion */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF HEALTH PROMOTION AND EDUCATION
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.department ==
                                    "Health Promotion and Education"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Emergency Medical Care */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF EMERGENCY MEDICAL CARE
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.department ==
                                    "Emergency Medical Care"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Nutrution and Dietics */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF NUTRITION AND DIETICS
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.department ==
                                    "Nutrition and Dietetics"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Clinical Technology */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF CLINICAL TECHNOLOGY
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.department == "Clinical Technology"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Counselling */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF COUNSELLING
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.department == "Counselling"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Phamacy */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF PHARMACY
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) => course.department == "Pharmacy"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {/* Library */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="subtitle1" align="center">
                              DEPARTMENT OF LIBRARY AND INFORMATION SERVICES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.values(universityCourses)
                                .filter(
                                  (course) =>
                                    course.department ==
                                    "Library and Information Services"
                                )
                                .map((course) => {
                                  return (
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setSelectedCourse(course);
                                          handleOpenCourse();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <VisibilityRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={course.title} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                }
              })()}
            </Grid>
          </Grid>
          {/* <Button onClick={handleOpenCourse}>Course open</Button> */}
        </Box>
      </Modal>
      <Modal
        open={openCourse}
        onClose={handleCloseCourse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflowY: "auto",
        }}
        courses={selectedCourse}
      >
        <Box sx={modalStyle}>
          <Grid display={"flex"} justifyContent={"flex-end"}>
            <IconButton onClick={handleCloseCourse}>
              <DisabledByDefaultRoundedIcon />
            </IconButton>
          </Grid>
          <Grid container direction={"column"} rowSpacing={4} p={5}>
            {/* {console.log(selectedCourse)} */}
            {(() => {
              switch (name) {
                case "University Of Botswana":
                  return (
                    <>
                      {/* 
                    
                    "title",
                    "duration",
                    "requirements",
                    "additionalInfo",
                    "faculty",
                    "enrollmentType",
                    "applicationFee",
                    "careerOpportunities",
                    "courseType",
                    "description",
                    "enrolled",
                    "graduated",
                    "category",
                    "featured",
                    "state",
                    "imageUrl",
                    "enrollmentType",
                    
                    */}
                      <Grid container direction={"column"} rowSpacing={2}>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Title
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.title}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Description
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.title}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Requirements
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.requirements}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Additional Info
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.additionalInfo}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Level
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.courseType}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Enrollment Type
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.enrollmentType}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Application Fee
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.applicationFee}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Career Opportunities
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.careerOpportunities}
                          </Grid>
                        </Grid>
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{ marginTop: "40px" }}
                        >
                          <Button
                            variant="contained"
                            href="https://www.ub.bw/sites/default/files/2020-04/UNDERGRADUATE-APPLICATION-FORM.pdf"
                            target={"_blank"}
                          >
                            Apply Now
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "GUC":
                  return (
                    <>
                      {/* 
                    "title",
                    "description",
                    "duration",
                    "totalFee",
                    "installments",
                    "applicationFee",
                    "examFee",
                    "finalExamFee",
                    "midSemesterFee",
                    "deposit",
                    "courseType",
                    "careerOpportunities",
                    "enrolled",
                    "graduated",
                    "category",
                    "featured",
                    "state",
                    "imageUrl",

                    */}
                      <Grid container direction={"column"} rowSpacing={2}>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Title
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.title}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Description
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.description}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Duration
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.duration}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Total Fee
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.totalFee}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Installments
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.installments}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Application Fee
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.applicationFee}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Exam Fee
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.examFee}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Final Exam Fee
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.finalExamFee}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Mid Semester Fee
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.midSemesterFee}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Deposit
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.deposit}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Level
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.courseType}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Career Opportunities
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.careerOpportunities}
                          </Grid>
                        </Grid>
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{ marginTop: "40px" }}
                        >
                          <Button
                            variant="contained"
                            href="http://www.guc.ac.bw/Application%20Form.pdf"
                            target={"_blank"}
                          >
                            Apply Now
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
                case "Boitekanelo College":
                  return (
                    <>
                      {/* 
                  
                  "title",
                  "applicationFee",
                  "requirements",
                  "duration",
                  "whatYouCanStudy",
                  "description",
                  "careerOpportunities",
                  "courseType",
                  "enrolled",
                  "graduated",
                  "category",
                  "featured",
                  "state",
                  "imageUrl",
                  "department",

                  */}

                      <Grid container direction={"column"} rowSpacing={2}>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Title
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.title}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Description
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.description}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Requirements
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.requirements}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Duration
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.duration}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Level
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.courseType}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            What You Can Study
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.whatYouCanStudy}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item xs={12} md={3} lg={3}>
                            Career Opportunities
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            {selectedCourse.careerOpportunities}
                          </Grid>
                        </Grid>
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{ marginTop: "40px" }}
                        >
                          <Button
                            variant="contained"
                            href="https://boitekanelo.ac.bw/wp-content/uploads/2021/02/Boitekanelo-College-Application-Form-2020.pdf"
                            target={"_blank"}
                          >
                            Apply Now
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
              }
            })()}
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openAdvice}
        onClose={handleCloseAdvice}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflowY: "auto",
        }}
      >
        <Box sx={modalStyle}>
          <Grid container direction={"column"} rowSpacing={4}>
            <Grid item>
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {Object.values(universityAdvice).map((advice) => {
                  return (
                    <>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt={advice.user.name}
                            src={advice.user.photoURL}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={advice.user.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {advice.comment.substr(0, 250) + "..."}
                              </Typography>
                              {/* {
                                " — I'll be in your neighborhood doing errands this…"
                              } */}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  );
                })}
              </List>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignContent={"flex-end"}
            >
              <Grid>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: { sx: "100%", xs: "100%", md: "80%", lg: 800 },
                    // height: 50,
                  }}
                >
                  {(userData[0] && <Avatar src={userData[0].photoURL} />) || (
                    <CircularProgress />
                  )}
                  {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <MenuIcon />
                  </IconButton> */}
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Comment"
                    name="comment"
                    onChange={handleChange}
                    value={formFields.comment}
                    // inputProps={{ "aria-label": "search google maps" }}
                  />
                  {/* <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton> */}
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={() => {
                      if (formFields.comment === "") {
                        // alert("Cannot post empty comment");
                        setSnackbarMessage("Cannot post empty advice");
                        handleOpenSnackBar();
                        return;
                      }
                      addAdvice(university, formFields);
                      setFormFields(defaultformFields);
                      setSnackbarMessage(
                        "Advice posted successfully and is under review."
                      );
                      handleOpenSnackBar();
                      // handleCloseAdvice();
                    }}
                  >
                    <ForwardRoundedIcon />
                  </IconButton>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleCloseSnackBar();
                handleCloseAdvice();
              }}
            >
              <DisabledByDefaultRoundedIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};
export default UniversitiesCard;

import React, { createContext, useState, useEffect } from "react";
import CounsilorsData from "../../mockdata/counsilors.json";
import { getCouncillors } from "../../utils/firebase/firebase";

export const CounsilorsContext = createContext({
  counsilors: {},
});

export const CounsilorsProvider = ({ children }) => {
  const [counsilorsMap, setCounsilorsMap] = useState({});

  useEffect(() => {
    const getCounsilorsMap = async () => {
      const counsilorMap = await getCouncillors("consultants");
      setCounsilorsMap(counsilorMap);
    };

    getCounsilorsMap();
  }, []);

  const value = { counsilorsMap };

  return (
    <CounsilorsContext.Provider value={value}>
      {children}
    </CounsilorsContext.Provider>
  );
};

import React, { useContext, Fragment } from "react";
import AppHeader from "../../components/appHeader";
import UniversitiesCard from "../../components/cards/universities";
import { Grid } from "@mui/material";
import { UniversitiesContext } from "../../contexts/universities";

const Universities = () => {
  const { universitiesData } = useContext(UniversitiesContext);
  return (
    <>
      <AppHeader section={"Universities"} />
      <Grid
        container
        direction="row"
        columnSpacing={4}
        rowSpacing={4}
        marginTop="40px"
      >
        {/* <Fragment> */}
        {/* {Object.keys(universitiesData || {}).map((id) => (
            <Fragment key={id}> */}
        {Object.values(universitiesData).map((university) => (
          <Grid item xs={12} md={3} key={university.id}>
            <UniversitiesCard
              id={university.id}
              logo={university.imageUrl}
              name={university.name}
              desc={university.desc}
            />
          </Grid>
        ))}
        {/* </Fragment>
          ))} */}
        {/* </Fragment> */}
      </Grid>
    </>
  );
};

export default Universities;

import React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";

const labelStyle = {
  color: "white",
  ".css-1wp7n7m-MuiFormLabel-root.Mui-focused": {
    color: "white",
  },
};

const radioStyle = {
  ".css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
    color: "white",
  },
  ".css-vqmohf-MuiButtonBase-root-MuiRadio-root": {
    color: "white",
  },
};

const Step1 = ({
  fullname,
  handleChange,
  textBoxStyle,
  phoneNumber,
  email,
  gender,
  dob,
  handleTimeChange,
  timeValue,
  datePickerStyle,
}) => {
  return (
    <Grid>
      <Grid item>
        <TextField
          variant="filled"
          sx={{ ...textBoxStyle }}
          fullWidth
          label={"Name and Surname"}
          onChange={handleChange}
          name={"fullname"}
          value={fullname}
        />
      </Grid>
      <Grid item mt={2}>
        <TextField
          sx={{ ...textBoxStyle }}
          fullWidth
          variant="filled"
          label={"Phone Number"}
          onChange={handleChange}
          name={"phoneNumber"}
          value={phoneNumber}
        />
      </Grid>
      <Grid item mt={2}>
        <TextField
          sx={{ ...textBoxStyle }}
          fullWidth
          variant="filled"
          label={"Email"}
          name={"email"}
          value={email}
          onChange={handleChange}
        />
      </Grid>
      <Grid item mt={2}>
        <FormControl>
          <FormLabel sx={{ ...labelStyle }} id="demo-radio-buttons-group-label">
            Gender
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="gender"
            value={gender}
            sx={{ ...radioStyle }}
          >
            <FormControlLabel
              //   sx={{ color: "#FFF" }}
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item mt={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ ...datePickerStyle }}
            disableFuture
            label="Date of Birth"
            //   openTo="year"
            //   views={['year', 'month', 'day']}
            value={timeValue}
            onChange={handleTimeChange}
            renderInput={(params) => <TextField {...params} />}
            name={"dob"}
            inputFormat="DD/MM/YYYY"
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default Step1;

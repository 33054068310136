import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Scholarships from "../../scholarships";
import Courses from "../../courses";
import News from "../../news";
import { CoursesProvider } from "../../../contexts/courses";
import { ScholarshipsProvider } from "../../../contexts/scholarships";
import { NewsProvider } from "../../../contexts/news";
import { styled } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#343434",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: "#DEDFE3",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 700,
    letterSpacing: "1.5px",
    "&.Mui-selected": {
      color: "#343434",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} pl={2}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Courses" {...a11yProps(0)} />
          <StyledTab label="Scholarships" {...a11yProps(1)} />
          <StyledTab label="News" {...a11yProps(2)} />
        </StyledTabs>
        {/* <Box sx={{ p: 3 }} /> */}
      </Box>
      <TabPanel value={value} index={0}>
        <CoursesProvider>
          <Courses />
        </CoursesProvider>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ScholarshipsProvider>
          <Scholarships />
        </ScholarshipsProvider>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <NewsProvider>
          <News />
        </NewsProvider>
      </TabPanel>
    </Box>
  );

  // return (
  //   <Box sx={{ width: "100%" }}>
  //     <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
  //       <Tabs
  //         sx={{ ...tabStyle }}
  //         value={value}
  //         onChange={handleChange}
  //         aria-label="basic tabs example"
  //         textColor="secondary"
  //         indicatorColor="secondary"
  //         variant="scrollable"
  //         scrollButtons="auto"
  //         allowScrollButtonsMobile
  //       >
  //         <Tab label="Courses" {...a11yProps(0)} />
  //         <Tab label="Scholarships" {...a11yProps(1)} />
  //         <Tab label="News" {...a11yProps(2)} />
  //       </Tabs>
  //     </Box>
  //     <TabPanel value={value} index={0}>
  //       {/* <CoursesProvider> */}
  //       <Courses />
  //       {/* </CoursesProvider> */}
  //     </TabPanel>
  //     <TabPanel value={value} index={1}>
  //       <ScholarshipsProvider>
  //         <Scholarships />
  //       </ScholarshipsProvider>
  //     </TabPanel>
  //     <TabPanel value={value} index={2}>
  //       <NewsProvider>
  //         <News />
  //       </NewsProvider>
  //     </TabPanel>
  //   </Box>
  // );
}

import React, { createContext, useState, useEffect } from "react";
import { getScholarships } from "../../utils/firebase/firebase";

export const ScholarshipsContext = createContext({
  scholarships: {},
});

export const ScholarshipsProvider = ({ children }) => {
  const [scholarshipsData, setScholarshipsData] = useState({});

  useEffect(() => {
    const getScholarshipsData = async () => {
      const scholarshipData = await getScholarships("scholarships");
      setScholarshipsData(scholarshipData);
    };

    getScholarshipsData();
  }, []);

  const value = { scholarshipsData };

  return (
    <ScholarshipsContext.Provider value={value}>
      {children}
    </ScholarshipsContext.Provider>
  );
};

import React, { createContext, useState, useEffect } from "react";
import {
  getAllCoursesByCategory,
  getCoursesByCategory,
} from "../../utils/firebase/firebase";

export const CoursesContext = createContext({
  coursesData: {},
  category: "",
  setCategory: () => {},
  setCoursesData: () => {},
  setCourseCategory: () => {},
  courseCategory: "",
});

export const CoursesProvider = ({ children }) => {
  const [coursesData, setCoursesData] = useState({});
  const [category, setCategory] = useState("");
  const [courseCategory, setCourseCategory] = useState("");

  // useEffect(() => {
  //   const getCoursesData = async () => {
  //     const courseData = await getCoursesByCategory(category);
  //     setCoursesData(courseData);
  //     setCategory(courseCategory);
  //     console.log("Category is", category);
  //     console.log("Course data", coursesData);
  //   };

  //   getCoursesData();
  // }, [coursesData, category]);

  useEffect(() => {
    const getCoursesData = async () => {
      const courseData = await getAllCoursesByCategory(category);
      setCoursesData(courseData);
      // setCategory(courseCategory);
      // console.log("Category is", category);
      // console.log("Course data", coursesData);
    };
    getCoursesData();
    // }, [coursesData, category]);
  }, [category]);

  const value = {
    coursesData,
    category,
    setCategory,
    setCoursesData,
    setCourseCategory,
    courseCategory,
  };

  return (
    <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>
  );
};
